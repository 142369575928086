import axios, {AxiosError, AxiosInstance} from 'axios';
import {Auth} from 'aws-amplify';
import * as Contracts from '@gfxco/contracts';
import {store} from '../app/store';
import {logoutAsync} from '../features/auth';

// To cancel all request on logout by expired refresh token
const controller = new AbortController();

const {
  REACT_APP_API_URL: apiURL,
  REACT_APP_LEGACY_API_URL: legacyApiURL,
  REACT_APP_PAYMENT_SERVICE_API_URL: paymentServiceApiURL,
  REACT_APP_SHIRT_APP_SHOP_ID,
} = process.env;

let client: AxiosInstance | undefined;
let publicClient: AxiosInstance | undefined;
let legacyClient: AxiosInstance | undefined;
let paymentServiceClient: AxiosInstance | undefined;

export const reloadAllClients = () => {
  client = undefined;
  publicClient = undefined;
  legacyClient = undefined;
  paymentServiceClient = undefined;
};

export const loadClient = async () => {
  if (client) {
    return client;
  }

  try {
    const isUserAuthenticated = await Auth.currentUserInfo();

    if (isUserAuthenticated) {
      const token = (await Auth.currentSession()).getIdToken().getJwtToken();

      const currentShop = store.getState().selectedShop.value?.id;

      const headers: {shopId: number | undefined; Authorization?: string} = {
        shopId: currentShop,
      };

      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }

      client = axios.create({
        baseURL: `${apiURL}`,
        headers,
        signal: controller.signal,
      });

      createRefreshTokenInterceptor(client);
    } else {
      client = axios.create({
        baseURL: `${apiURL}`,
      });
    }

    return client;
  } catch (error) {
    console.error(error);
    return axios;
  }
};

const loadPublicClient = async () => {
  if (publicClient) {
    return publicClient;
  }

  try {
    publicClient = axios.create({
      baseURL: `${apiURL}`,
      signal: controller.signal,
    });

    return publicClient;
  } catch (error) {
    console.error(error);
    return axios;
  }
};

// eslint-disable-next-line no-unused-vars
const loadLegacyClient = async () => {
  if (legacyClient) {
    return legacyClient;
  }

  try {
    const user = await Auth.currentAuthenticatedUser();
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();

    legacyClient = axios.create({
      baseURL: `${legacyApiURL}/profile/${user.username}`,
      headers: {Authorization: `Bearer ${token}`},
      signal: controller.signal,
    });

    createRefreshTokenInterceptor(legacyClient);
    return legacyClient;
  } catch (error) {
    console.error(error);
    return axios;
  }
};

export const loadPaymentServiceClient = async () => {
  if (paymentServiceClient) {
    return paymentServiceClient;
  }

  try {
    const isUserAuthenticated = await Auth.currentUserInfo();

    if (isUserAuthenticated) {
      const token = (await Auth.currentSession()).getIdToken().getJwtToken();

      const headers = token ? {Authorization: `Bearer ${token}`} : undefined;
      paymentServiceClient = axios.create({
        baseURL: `${paymentServiceApiURL}`,
        headers,
        signal: controller.signal,
      });

      createRefreshTokenInterceptor(paymentServiceClient);
    } else {
      throw new Error('User is not authenticated');
    }

    return paymentServiceClient;
  } catch (error) {
    console.error(error);
    return axios;
  }
};

export const handleError = async (e: AxiosError | Error | unknown) => {
  if (axios.isAxiosError(e)) {
    // Access to config, request, and response
    if (e.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return Promise.reject(e.response.data);
    } else if (e.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.error(Error(e.request));
      return Promise.reject(
        Error('The request was made but no response was received'),
      );
    } else {
      // Just a stock error
      console.error(e);
      return Promise.reject(Error(e.message));
    }
  }
  throw e;
};

export const createRefreshTokenInterceptor = (
  client: AxiosInstance | undefined,
) => {
  if (client) {
    client.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;

        if (originalRequest._retry) {
          // Logout user if refresh token is invalid
          controller.abort();
          store.dispatch(logoutAsync());
        }

        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;

          // Force refresh token
          const isUserAuthenticated = await Auth.currentAuthenticatedUser({
            bypassCache: true,
          });
          if (isUserAuthenticated) {
            const token = (await Auth.currentSession())
              .getIdToken()
              .getJwtToken();
            originalRequest.headers.Authorization = `Bearer ${token}`;

            // Updating the client Auth header
            client.defaults.headers.common.Authorization = `Bearer ${token}`;
            if (client) {
              return client(originalRequest);
            }
          }

          const currentShop = store.getState().selectedShop.value?.id;

          if (currentShop) {
            originalRequest.headers.shopId = currentShop;
            client.defaults.headers.shopId = currentShop;
          }
        }
        return Promise.reject(error);
      },
    );
  }
};

export const getShops = async () => {
  try {
    const client = await loadClient();
    const response = await client.get<Contracts.GetShopsResponse[]>(`/shops`);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getModerationOrders = async (
  parameters: Contracts.IModerationOrdersRequest,
) => {
  try {
    const client = await loadClient();
    const response = await client.get<Contracts.IModerationOrdersResponse>(
      `/moderation/orders`,
      {
        params: parameters,
      },
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getDesignLink = async (
  parameters: Contracts.ValidateDesignEditLinkRequest,
) => {
  try {
    const client = await loadClient();
    const response = await client.get<boolean>(`/public/validate-design-link`, {
      params: parameters,
    });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const updateEditDesignLinkStatus = async (
  token: string,
  status: string,
) => {
  try {
    const client = await loadClient();
    await client.put<void>(`/public/update-design-link/`, {
      token,
      status,
    });
  } catch (error) {
    return handleError(error);
  }
};

export const getSkus = async (filters: Contracts.SkuRequest) => {
  try {
    const client = await loadClient();
    const searchParams = new URLSearchParams({...filters});
    const response = await client.get<Contracts.SkuDBModel[]>(
      `/template/skus?${searchParams.toString()}`,
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const getNeckLabels = async (filters: Contracts.NeckLabelRequest) => {
  try {
    const client = await loadClient();
    const response = await client.get<Contracts.NeckLabelDBModel[]>(
      `/shops/${filters.shopId}/neck-labels`,
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const generateArts = async (params: Contracts.GenerateArtRequest) => {
  try {
    const client = await loadClient();
    const response = await client.post<Contracts.GenerateArtResponse>(
      '/lambda/generate-arts',
      params,
    );

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const sendToPrintful = async (params: Contracts.SamplePortalLog) => {
  try {
    const client = await loadClient();
    const response = await client.post<void>('/send-printer/printful', params);

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const sendToDubow = async (params: Contracts.SamplePortalLog) => {
  try {
    const client = await loadClient();
    const response = await client.post<void>('/send-printer/dubow', params);

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getSamplePortalLogs = async (
  filters: Contracts.SamplePortalLogFilters,
) => {
  try {
    const client = await loadClient();
    const searchParams = new URLSearchParams({
      offset: filters.offset.toString(),
      limit: filters.limit.toString(),
    });
    const response = await client.get<Contracts.SamplePortalLogDB[]>(
      `/sample-portal/log?${searchParams.toString()}`,
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const getCookiePolicy = async () => {
  try {
    const client = await loadPublicClient();

    const response = await client.get(
      `/shops/${REACT_APP_SHIRT_APP_SHOP_ID}/pages/cookies`,
    );

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const getPrivacyPolicy = async () => {
  try {
    const client = await loadPublicClient();

    const response = await client.get(
      `/shops/${REACT_APP_SHIRT_APP_SHOP_ID}/pages/privacy`,
    );

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const getShopDeveloperOptions = async (shopId: number) => {
  try {
    const client = await loadClient();
    const response = await client.get<Contracts.DeveloperOptionsResponse>(
      `/shops/${shopId}/developer-options`,
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getShopConfigs = async (shopId: number) => {
  try {
    const client = await loadClient();
    const response = await client.get<Contracts.ShopConfigsResponse>(
      `/shops/${shopId}/configs`,
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const updateOrderPipeline = async (
  params: Contracts.UpdateOrderPipelineRequest,
) => {
  try {
    const client = await loadClient();
    const response = await client.put<void>('/shops/orderPipeline', params);

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getProductTypes = async (params: Contracts.ProductTypeRequest) => {
  try {
    const client = await loadClient();
    const response = await client.get<Contracts.ProductTypeDBModel[]>(
      `product/types`,
      {
        params,
      },
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getProductSkus = async (params: {
  searchBy?: string;
  productType: string;
}) => {
  try {
    const client = await loadClient();
    const response = await client.get<Contracts.ProductSkusResponse[]>(
      `product/skus`,
      {
        params,
      },
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const saveTemplateFromBuilder = async (
  params: Contracts.CreateTemplateCanvaRequest,
) => {
  try {
    const client = await loadClient();
    return await client.post('template/builder', params);
  } catch (error) {
    return handleError(error);
  }
};

export const updateTemplateFromBuilder = async (
  data: Contracts.CreateTemplateCanvaRequest,
) => {
  try {
    const client = await loadClient();
    return await client.put('template/builder', data);
  } catch (error) {
    return handleError(error);
  }
};

export const getOrderManagement = async (
  params: Contracts.ManagementOrdersRequest,
) => {
  try {
    const client = await loadClient();
    const response = await client.get<Contracts.ManagementOrdersResponse>(
      `orders`,
      {
        params,
      },
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getFonts = async (params: Contracts.FontsRequest) => {
  const {shopId, sortBy, name, offset, limit} = params;
  try {
    const client = await loadClient();
    const response = await client.get<Contracts.FontsResponse>(
      `/shops/${shopId}/fonts?sortBy=${sortBy}&name=${name}&offset=${offset}&limit=${limit}`,
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getDesignTemplate = async (templateId: number) => {
  try {
    const client = await loadClient();
    const response = await client.get<Contracts.GetTemplateByIdResponse>(
      `/templates/${templateId}`,
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const requestUploadLink = async (
  pathParams: Contracts.uploadPathParameters,
  queryParams: Contracts.uploadQueryParameters,
  body: Contracts.uploadRequest,
) => {
  try {
    const {shopId} = pathParams;
    const {folder} = queryParams;
    const {mime, key} = body;

    const client = await loadClient();
    const response = await client.post<Contracts.uploadResponse>(
      `/shops/${shopId}/upload`,
      {
        mime,
        key,
      },
      {
        params: {
          folder,
        },
      },
    );

    return response.data.link;
  } catch (error) {
    handleError(error);
  }
};

export const deleteTemplate = async (templateId: number) => {
  try {
    const client = await loadClient();
    const response = await client.delete<boolean>(`/template/${templateId}`);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getModerationFilters = async (
  parameters: Contracts.IGetModerationFiltersRequest,
) => {
  try {
    const client = await loadClient();
    const response = await client.get<Contracts.IGetModerationFiltersResponse>(
      `/moderation/filters`,
      {
        params: parameters,
      },
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const generateDesignEditLink = async (
  body: Contracts.DesignEditLinkRequest,
) => {
  try {
    const client = await loadPublicClient();
    const response = await client.post<Contracts.DesignEditLinkDBModel>(
      `/public/generate-design-link`,
      body,
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getDesignEditLink = async (
  params: Contracts.DesignEditLinkRequest,
) => {
  try {
    const client = await loadPublicClient();
    const response = await client.get<Contracts.DesignEditLinkDBModel>(
      `/public/design-edit-link`,
      {
        params,
      },
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};
