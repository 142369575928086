import {IColor, ProductSkusResponse} from '@gfxco/contracts';
import React from 'react';
import HasNeckLabel from '../HasNeckLabel';
import GFXImage from '../Image';
import mapSizes from '../../libs/mapSizes';
import Icon from '../Icons/Icon';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

type ProductSkuProps = {
  item: ProductSkusResponse;
  productType: string;
  isSelected: boolean;
  handleOnClick: (item: ProductSkusResponse) => void;
};
const ProductSku: React.FC<ProductSkuProps> = (props) => {
  const {item, productType, isSelected, handleOnClick} = props;
  const sizesMapped = mapSizes(item.sizes, productType);

  const Sizes = () => {
    if (typeof sizesMapped === 'string') {
      return <em>{sizesMapped}</em>;
    }
    return (
      <>
        <em>{sizesMapped[0]}</em> to <em>{sizesMapped[1]}</em>
      </>
    );
  };

  return (
    <div
      className={`SelectProductSku__item ${
        isSelected ? ' SelectProductSku__Selected ' : ''
      }`}
      key={`${item.description}-${item.printer}`}
      onClick={() => handleOnClick(item)}
    >
      <header className="SelectProductSku__Header">
        <span className="SelectProductSku__Header__Printer">
          Fulfillment by {item.printer}{' '}
          <OverlayTrigger
            placement="top"
            trigger={['hover', 'focus']}
            overlay={
              <Tooltip className="white-tooltip">
                To accept a future order in this SKU this fulfillment service
                should be configured.
              </Tooltip>
            }
          >
            <div>
              <Icon name="info" />
            </div>
          </OverlayTrigger>
        </span>
        <span className="SelectProductSku__Header__Title">
          {item.description}
        </span>
      </header>
      <div className="SelectProductSku__Image">
        <GFXImage src={item.url} placeholderSrc="/loading-render.gif" />
      </div>
      <div className="SelectProductSku__Info">
        <div className="SelectProductSku__Text SelectProductSku__Sizes">
          <span>
            Sizes: <Sizes />
          </span>
        </div>
        <div className="SelectProductSku__Text SelectProductSku__PrintableArea">
          <span>Maximum printable area:</span>
          {productType === 'framedPoster' ? (
            <span>
              <em>
                {item.frontHeightDimension}x{item.frontWidthDimension} in
              </em>
            </span>
          ) : (
            <span>
              <em>
                {item.frontHeightDimension}x{item.frontWidthDimension} in
              </em>{' '}
              and{' '}
              <em>
                {item.backHeightDimension}x{item.backWidthDimension} in
              </em>
            </span>
          )}
        </div>
        <HasNeckLabel hasLabel={item.hasLabel || false} />
        <div className="SelectProductSku__Text SelectProductSku__ColorsLabel">
          <span>Colors available:</span>
          <div className="SelectProductSku__Colors">
            {item.colors.map((color: IColor) => (
              <div
                key={color.hex}
                className="SelectProductSku__ColorItem"
                style={{backgroundColor: color.hex}}
              ></div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductSku;
