import React, {useState} from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import {deleteTemplate} from '../../api';
import CloseConfirmationModal from '../CloseConfirmationModal';

import './DeleteTemplate.scss';
import Icon from '../Icons/Icon';

interface RequiredProps {
  templateId: number;
  updateTemplates: () => void;
}

type DeleteTemplateProps = RequiredProps;

const DeleteTemplate: React.FC<DeleteTemplateProps> = (props) => {
  const {templateId, updateTemplates} = props;

  const [showWarningModal, setShowWarningModal] = useState(false);

  const handleDeleteTemplate = async () => {
    try {
      await deleteTemplate(templateId);
      updateTemplates();
      setShowWarningModal(false);
    } catch (error) {
      console.error(error);
      setShowWarningModal(false);
    }
  };

  return (
    <div id="DeleteTemplateButton">
      <Dropdown.Item href="#" onClick={() => setShowWarningModal(true)}>
        <Icon name="delete" /> Delete
      </Dropdown.Item>
      <CloseConfirmationModal
        show={showWarningModal}
        onContinue={() => setShowWarningModal(false)}
        onClose={handleDeleteTemplate}
        title="Are you sure you want to delete this template?"
        text="Once deleted, this template cannot be recovered. Are you sure you want to proceed?"
        continueText="No, close window"
        closeText="Yes, delete templete"
      />
    </div>
  );
};

export default DeleteTemplate;
