import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
  IDraftCanva,
  INextStep,
  IColor,
  ISize,
  ISizeOptions,
  IImage,
  constants,
} from '@gfxco/contracts';
import {IClientEvent} from '@best-apps/gfx-editor/lib/package/dist/types/GFXEvents';
import StepBuilder from '../../components/StepBuilder';
import SetDesignType from '../../components/SetDesignType';
import SelectProductType from '../../components/SelectProductType';
import SelectProductSku from '../../components/SelectProductSku';
import TemplateBuilderEditor from '../../components/TemplateBuilder';
import {useGFXInstance} from '@best-apps/gfx-editor';
import {useAppSelector, useAppDispatch} from '../../app/hooks';
import {selectShop} from '../../features/shops/selectedShop';

import {
  resetDraftCanvas,
  getDraftCanvaById,
  getDraftCanvaAsync,
} from '../../features/draftCanvas/loadDraftCanvas';

import {
  getDetailFetchStatus,
  getEntityById,
  getDesignTemplateDetailAsync,
} from '../../features/designTemplates/loadDesignTemplates';

import {saveTemplateFromBuilder, updateTemplateFromBuilder} from '../../api';
import DraftCanvasApi from '../../api/draftCanvasApi';
import {getNextTemplateId} from '../../api/templates';

import './DesignWizard.scss';
import TemplateBuilderButtons, {
  extractSlots,
} from '../../components/TemplateBuilder/TemplateBuilderButtons';
import getArrayDiff from '../../libs/getArrayDiff';
import ConfigureProductInfo from '../../components/ConfigureProductInfo';
import NotFound from '../../components/NotFound/NotFound';

type DesignWizardRouteParams = {
  templateId: string;
  draftCanvasId: string;
};

type ProofSizes = 'high' | 'medium' | 'low';

interface NextValidationObject {
  [key: number]: (data: IDraftCanva) => boolean;
}

interface BuilderImageSlotMap {
  [key: string]: IImage;
}

const DesignWizard: React.FC = () => {
  const routeParams =
    useParams<DesignWizardRouteParams>() as DesignWizardRouteParams;

  const templateId = parseInt(routeParams.templateId, 10);
  const draftCanvasId = parseInt(routeParams.draftCanvasId, 10);
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [showSaveWarning, setShowSaveWarning] = React.useState(false);
  const [selectedStep, setSelectedStep] = React.useState(1);
  const [currentDesign, setCurrentDesign] = React.useState<IDraftCanva>({});
  const initialDesign = React.useRef<IDraftCanva>({});
  const [builderImageMap, setBuilderImageMap] =
    React.useState<BuilderImageSlotMap>({});
  const [productName, setProductName] = React.useState(
    currentDesign.productName,
  );

  const addNewImageMap = (image: IImage, slot: string) => {
    return setBuilderImageMap((oldImageMap) => ({
      ...oldImageMap,
      [slot]: image,
    }));
  };

  const activateSaveWarning = () => {
    setShowSaveWarning(true);
  };

  const addNewImageMapForDuplicated = (
    parentSlotUuid: string,
    parentImageId: number | null | undefined,
    newSlotUuid: string,
  ) => {
    const image = builderImageMap[parentSlotUuid];
    if (image) {
      setBuilderImageMap((oldImageMap) => ({
        ...oldImageMap,
        [newSlotUuid]: image,
      }));
    } else if (parentImageId) {
      setBuilderImageMap((oldImageMap) => ({
        ...oldImageMap,
        [newSlotUuid]: {
          id: parentImageId,
          name: constants.BUILDER.DUPLICATED_IMAGE,
        },
      }));
    }
  };

  const detailFetchStatus = useAppSelector(getDetailFetchStatus);
  const id = (currentDesign.id as number) || templateId;
  const slotTemplate = useAppSelector((state) => getEntityById(state, id));
  const draftCanva = useAppSelector((state) =>
    getDraftCanvaById(state, draftCanvasId),
  );

  const navigate = useNavigate();

  const gfx = useGFXInstance();

  React.useEffect(() => {
    let removeListener: () => void;
    if (gfx) {
      removeListener = gfx?.addGFXCustomEventListener((event: IClientEvent) => {
        const {type, payload} = event;
        console.log(`GFX Event ${type}:`, payload);
      });
    } else {
      removeListener = () => {};
    }

    return () => {
      removeListener();
    };
  }, [gfx]);

  const emptyColors: IColor[] = [];
  const emptySizes: ISize[] = [];
  const selectedShop = useAppSelector(selectShop);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (draftCanva) {
      setCurrentDesign(draftCanva);
      initialDesign.current = draftCanva;
    }
  }, [draftCanva?.id]);

  React.useEffect(() => {
    if (draftCanvasId && !draftCanva) {
      dispatch(getDraftCanvaAsync(draftCanvasId));
    }
  }, [draftCanvasId]);

  React.useEffect(() => {
    if (templateId) {
      dispatch(
        getDesignTemplateDetailAsync({
          templateId,
          canvasId: slotTemplate?.canvasId,
        }),
      );
    }
  }, [templateId]);

  React.useEffect(() => {
    defineCurrentStep();
  }, [currentDesign.id]);

  React.useEffect(() => {
    if (detailFetchStatus === 'success' && slotTemplate) {
      const {template, canvas, templateSkuCatalog, colors, sizes} =
        slotTemplate;

      if (template && canvas && templateSkuCatalog) {
        const currentDesign = {
          id: template.id,
          productName: template.name,
          productType: template!.designData!.type,
          sizes,
          colors,
          selectedColors: templateSkuCatalog
            ?.map((sku) => {
              return {
                id: sku.colorId,
                name: sku.colorCode,
                hex: sku.colorHexCode,
              };
            })
            .filter((color, index) => {
              return (
                templateSkuCatalog.indexOf(
                  templateSkuCatalog.find((sku) => sku.colorId === color.id)!,
                ) === index
              );
            }),
          selectedSizes: templateSkuCatalog
            ?.map((sku) => ({
              id: sku.sizeId,
              code: sku.sizeCode as ISizeOptions,
            }))
            .filter((size, index) => {
              return (
                templateSkuCatalog.indexOf(
                  templateSkuCatalog.find((sku) => sku.sizeId === size.id)!,
                ) === index
              );
            }),
          printer: templateSkuCatalog[0].printerCode,
          skuDescription: templateSkuCatalog[0].manufacturerDescription,
          hasLabel: templateSkuCatalog[0].hasLabel,
          shopId: template.shopId,
          canvasId: canvas.id,
        };

        setCurrentDesign(currentDesign);
        initialDesign.current = currentDesign;
      }
    }
  }, [detailFetchStatus]);

  const handleProductNameChange = (name: string) => {
    if (isSaving) {
      return;
    }
    setShowSaveWarning(true);
    setProductName(name);
  };

  const defineCurrentStep = () => {
    if (
      (currentDesign.selectedColors && currentDesign.selectedColors.length) ||
      (currentDesign.selectedSizes && currentDesign.selectedSizes.length)
    ) {
      setSelectedStep(4);
    } else if (currentDesign.skuDescription) {
      setSelectedStep(3);
    } else if (currentDesign.productType) {
      setSelectedStep(2);
    } else {
      setSelectedStep(1);
    }
  };

  const nextValidations: NextValidationObject = {
    1: () => !!productName,
    2: (data: IDraftCanva) => !!data.productType,
    3: (data: IDraftCanva) => !!data.skuDescription,
    4: (data: IDraftCanva) =>
      !!data.selectedColors?.length && !!currentDesign.selectedSizes?.length,
  };

  const onNextStep = (params: INextStep) => {
    if (isSaving) {
      return;
    }
    if (params.step > 3) {
      if (currentDesign.skuDescription !== params.data.skuDescription) {
        setCurrentDesign((oldDesignData) => ({
          ...oldDesignData,
          selectedColors: [],
          selectedSizes: [],
        }));
      }
    }

    for (const attribute of Object.keys(params.data)) {
      const value = params.data[attribute as keyof IDraftCanva];
      const initialValue =
        initialDesign.current[attribute as keyof IDraftCanva];

      if (initialValue !== value) {
        setShowSaveWarning(true);
      }
    }

    setCurrentDesign((oldDesignData) => ({...oldDesignData, ...params.data}));
    setSelectedStep(params.step);
  };

  const setStep = (step: number) => {
    let declineSetStep = !currentDesign.productName;

    if (step === 2 && declineSetStep) {
      return;
    }

    declineSetStep = declineSetStep || !currentDesign.productType;

    if (step === 3 && declineSetStep) {
      return;
    }

    declineSetStep = declineSetStep || !currentDesign.skuDescription;

    if (step === 4 && declineSetStep) {
      return;
    }

    if (
      step === 5 &&
      (currentDesign.selectedColors?.length === 0 ||
        currentDesign.selectedSizes?.length === 0 ||
        declineSetStep)
    ) {
      return;
    }

    setSelectedStep(step);
  };

  const handleSelectedColors = (selectedColors: IColor[]) => {
    if (isSaving) {
      return;
    }
    const diff = getArrayDiff<IColor>(
      selectedColors,
      initialDesign.current.selectedColors || [],
    );
    if (diff) {
      setShowSaveWarning(true);
    }
    setCurrentDesign((oldDesignData) => ({...oldDesignData, selectedColors}));
  };

  const handleSelectedSizes = (selectedSizes: ISize[]) => {
    if (isSaving) {
      return;
    }
    const diff = getArrayDiff<ISize>(
      selectedSizes,
      initialDesign.current.selectedSizes || [],
    );
    if (diff) {
      setShowSaveWarning(true);
    }
    setCurrentDesign((oldDesignData) => ({...oldDesignData, selectedSizes}));
  };

  const saveDraftCanva = async () => {
    if (currentDesign.id) {
      await DraftCanvasApi.updateDraftCanvas(currentDesign);
    } else {
      await DraftCanvasApi.createDraftCanvas({
        ...currentDesign,
        shopId: selectedShop!.id,
      });
    }
    setShowSaveWarning(false);
    dispatch(resetDraftCanvas());
    setCurrentDesign({});
    setSelectedStep(1);
    return navigate(`/designs?tab=draft`);
  };

  const saveSlotTemplate = async () => {
    let templateId = slotTemplate?.id || slotTemplate?.template?.id;
    const extraProofs: ProofSizes[] = [];
    if (templateId === undefined) {
      const result = await getNextTemplateId();
      templateId = result?.new_id ?? 0;
    }
    setIsSaving(true);

    if (selectedShop && selectedShop.id && selectedShop.platform === 'CUSTOM') {
      extraProofs.push('high', 'medium');
    }

    const builderElements = await gfx!.actions.generateBuilderSlotsObjects({
      generateProofs: true,
      templateId: templateId!,
      proofs: extraProofs,
    });

    const template = slotTemplate?.template;
    const manufacturer = slotTemplate?.templateSkuCatalog
      ? slotTemplate?.templateSkuCatalog[0].manufacturerSkuId
      : undefined;

    const data = {
      templateId,
      productType: currentDesign.productType!,
      shopId: selectedShop!.id,
      color: builderElements.color,
      canvas: {
        name: currentDesign.productName,
        front: builderElements.front,
        back: builderElements.back,
      },
      template: {
        imagesMap: builderImageMap,
        name: currentDesign.productName as string,
        proofs: {
          front: builderElements.proofs.front!,
          back: builderElements.proofs.back!,
        },
      },
      catalogInfo: {
        colors: currentDesign.selectedColors!,
        sizes: currentDesign.selectedSizes!,
        printer: currentDesign.printer!,
      },
      canvasId: currentDesign.canvasId,
      draftCanvasId: draftCanvasId || undefined,
      manufacturerId: currentDesign.manufacturerId! || manufacturer,
    };

    let result;
    try {
      if (template && template.id) {
        result = await updateTemplateFromBuilder(data);
      } else {
        result = await saveTemplateFromBuilder(data);
      }
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setIsSaving(false);
    }
    setShowSaveWarning(false);
    setCurrentDesign({});
    setSelectedStep(1);
    return navigate(`/designs/controls/${result?.data.template.id}`);
  };

  const validateGfxEditorLoaded = () => {
    return (
      gfx &&
      currentDesign.selectedColors &&
      currentDesign.selectedColors.length &&
      currentDesign.selectedSizes &&
      currentDesign.selectedSizes.length &&
      currentDesign.printer &&
      selectedShop
    );
  };

  const handleSaveClick = async () => {
    if (!gfx && selectedStep <= 3) {
      return await saveDraftCanva();
    }

    if (!gfx) {
      console.warn(
        'GFX Instance not loaded. Check if this block is outside <GFXProvider/>',
      );
      return;
    }

    const frontObjects = extractSlots(gfx, 'front') || [];
    const backObjects = extractSlots(gfx, 'back') || [];

    if (frontObjects.length === 0 && backObjects.length === 0) {
      return await saveDraftCanva();
    }

    if (!validateGfxEditorLoaded()) {
      console.warn(
        'GFX Instance not loaded or insufficient data. Check if this block is outside <GFXProvider/>',
      );
      return;
    }

    await saveSlotTemplate();
  };

  const handleNextClick = () => {
    if (isSaving) {
      return;
    }
    if (!nextValidations[selectedStep](currentDesign)) {
      return;
    }

    if (selectedStep === 1 && productName) {
      setCurrentDesign((oldDesignData) => ({...oldDesignData, productName}));
    }

    setSelectedStep(selectedStep + 1);
  };

  const steps = [
    {
      name: 'Name',
      component: (
        <SetDesignType
          onNextStep={onNextStep}
          onInputChange={handleProductNameChange}
          initialProductName={currentDesign.productName || ''}
        />
      ),
    },
    {
      name: 'Product Type',
      validateStep: () => currentDesign.canvasId !== undefined,
      component: (
        <SelectProductType
          onNextStep={onNextStep}
          initialItemSelected={currentDesign.productType}
        />
      ),
    },
    {
      name: 'Catalog',
      component: (
        <SelectProductSku
          onNextStep={onNextStep}
          productType={currentDesign.productType}
          initialSkuDescription={currentDesign.skuDescription}
          initialPrinter={currentDesign.printer}
        />
      ),
    },
    {
      name: 'Product Info',
      component: (
        <ConfigureProductInfo
          onSelectColors={handleSelectedColors}
          onSelectSizes={handleSelectedSizes}
          productName={currentDesign.productName || ''}
          skuDescription={currentDesign.skuDescription || ''}
          hasLabel={currentDesign.hasLabel || false}
          colors={currentDesign.colors || emptyColors}
          sizes={currentDesign.sizes || emptySizes}
          selectedColors={currentDesign.selectedColors || []}
          selectedSizes={currentDesign.selectedSizes || []}
          onNextStep={handleNextClick}
        />
      ),
    },
    {
      name: 'Design',
      component: (
        <TemplateBuilderButtons
          isSaving={isSaving}
          displayColors={currentDesign.selectedColors || []}
          productName={currentDesign.productName || ''}
          onSlotsChange={() => {
            if (isSaving) {
              return;
            }
            setShowSaveWarning(true);
          }}
        />
      ),
    },
  ];

  const templateBuilderComponent = (
    <TemplateBuilderEditor
      setImageMap={addNewImageMap}
      setImageMapForDuplicated={addNewImageMapForDuplicated}
      activateSaveWarning={activateSaveWarning}
      canvasId={currentDesign.canvasId}
      shopId={selectedShop!.id}
      displayColors={currentDesign.colors}
      selectedColors={currentDesign.selectedColors}
      productType={currentDesign.productType!}
      manufacturerId={currentDesign.manufacturerId!}
      printer={currentDesign.printer!}
      templateId={draftCanvasId ? undefined : currentDesign.id}
      step={selectedStep}
    />
  );

  const showNextButton = selectedStep < 5 && selectedStep > 1;
  const showSaveButton = true;

  if (detailFetchStatus === 'error') {
    return <NotFound />;
  }

  return (
    <div id="DesignWizard">
      <StepBuilder
        isSaveEnabled={selectedStep > 1}
        isProgressWarningEnabled={showSaveWarning}
        steps={steps}
        templateBuilder={
          currentDesign.productType ? templateBuilderComponent : null
        }
        selectedStepNumber={selectedStep}
        setStep={setStep}
        onClickSave={handleSaveClick}
        onClickNext={handleNextClick}
        isNextEnabled={showNextButton}
        showSaveButton={showSaveButton}
        isLoading={detailFetchStatus === 'loading'}
      />
    </div>
  );
};

export default DesignWizard;
