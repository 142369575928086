import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
import Accordion from 'react-bootstrap/Accordion';
import {constants} from '@gfxco/contracts';
import {useAppSelector} from '../../../app/hooks';
import {selectUserPermissions} from '../../../features/user';

import Icon from '../../Icons/Icon';

/// Icons

import './NavigationMenu.scss';

const {REACT_APP_HELP_CENTER_URL: HelpCenterURL = ''} = process.env;
const {USER_PERMISSIONS} = constants;
interface RequiredProps {}

interface OptionalProps {
  open?: boolean;
}

type NavigationMenuProps = RequiredProps & OptionalProps;

const ActiveKeyPerPath: {[key: string]: string[]} = {
  Home: ['/', ' ', ''],
  Dashboard: ['dashboard'],
  Products: ['designs', 'designs/images', 'designs/fonts', 'products/labels'],
  Orders: ['orders', 'orders/moderation'],
  SamplePortal: ['sample-portal', 'sample-portal/log'],
  printerConfig: ['printers'],
  MyStore: ['my-store'],
  Settings: ['settings/subscriptions', 'settings/invoices', 'user-management'],
  Account: ['account'],
  'Log out': ['logout'],
};

export const NavigationMenu: React.FC<NavigationMenuProps> = ({open}) => {
  const [activeKey, setActiveKey] = useState<string>(' ');

  const navigate = useNavigate();
  const userPermissions = useAppSelector(selectUserPermissions);

  const checkUserPermissions = (permission: string) => {
    if (userPermissions === undefined) {
      return false;
    }

    return (
      userPermissions.includes(permission) ||
      userPermissions.includes(USER_PERMISSIONS.ALL) ||
      userPermissions.includes(USER_PERMISSIONS.DEVELOPER)
    );
  };

  useEffect(() => {
    const currentPath = location.pathname.split('/')[1];

    const activeKey = Object.keys(ActiveKeyPerPath).find((key) => {
      return ActiveKeyPerPath[key].includes(currentPath);
    });

    if (activeKey) {
      setActiveKey(activeKey);
    }
  }, [location.pathname]);

  const handleAccordionClick = (key: string) => {
    setActiveKey(activeKey === key ? ' ' : key);
  };

  return (
    <div id="NavigationMenu">
      <Accordion
        className={`${!open ? 'navigation-menu-closed' : ''}`}
        activeKey={activeKey}
      >
        <Accordion.Item eventKey="Dashboard" className="item item-hidden">
          <Accordion.Header
            className="not-sub-items menu-item"
            data-tooltip="Dashboard"
            onClick={() => {
              handleAccordionClick('Dashboard');
              navigate('dashboard');
            }}
          >
            <div className="menu-item-text">
              <Icon name="dashboard" />
              {open && 'Dashboard'}
            </div>
          </Accordion.Header>
        </Accordion.Item>
        <Accordion.Item eventKey="Home" className="item">
          <Accordion.Header
            className="not-sub-items setting-item  menu-item"
            data-tooltip="Home"
            onClick={() => {
              handleAccordionClick('Home');
              navigate('/');
            }}
          >
            <div className="menu-item-text">
              <Icon name="home" />
              {open && 'Home'}
            </div>
          </Accordion.Header>
        </Accordion.Item>

        {checkUserPermissions(USER_PERMISSIONS.PRODUCTS) && (
          <Accordion.Item eventKey="Products" className="item">
            <Accordion.Header
              className={`title menu-item ${!open ? 'not-sub-items' : ''}`}
              data-tooltip="Products"
              onClick={() => handleAccordionClick('Products')}
            >
              <div className="menu-item-text">
                <Icon name="product" />
                {open && 'Products'}
              </div>
            </Accordion.Header>
            <Accordion.Body
              className={`sub-items ${open ? 'sub-items-open' : ''}`}
            >
              <Nav.Link
                className={`${!open ? 'menu-item' : ''}`}
                onClick={() => navigate('designs')}
                data-tooltip="Designs"
                active={location.pathname === '/designs'}
              >
                <div className="menu-item-text">
                  <Icon name="label" /> {open && 'Designs'}
                </div>
              </Nav.Link>
              <Nav.Link
                className={`${!open ? 'menu-item' : ''}`}
                onClick={() => navigate('designs/images')}
                data-tooltip="Images"
                active={location.pathname === '/designs/images'}
              >
                <div className="menu-item-text">
                  <Icon name="image" /> {open && 'Images'}
                </div>
              </Nav.Link>
              <Nav.Link
                className={`${!open ? 'menu-item' : ''}`}
                data-tooltip="Fonts"
                onClick={() => navigate('designs/fonts')}
                active={location.pathname === '/designs/fonts'}
              >
                <div className="menu-item-text">
                  <Icon name="font" /> {open && 'Fonts'}
                </div>
              </Nav.Link>
              <Nav.Link
                className={`${!open ? 'menu-item' : ''}`}
                onClick={() => navigate('products/labels')}
                data-tooltip="Labels"
                active={location.pathname === '/products/labels'}
              >
                <div className="menu-item-text">
                  <Icon name="label" /> {open && 'Labels'}
                </div>
              </Nav.Link>
              <NavDropdown.Divider />
            </Accordion.Body>
          </Accordion.Item>
        )}
        {checkUserPermissions(USER_PERMISSIONS.ORDERS) && (
          <Accordion.Item eventKey="Orders" className="item">
            <Accordion.Header
              data-tooltip="Orders"
              className={`menu-item ${!open ? 'not-sub-items' : ''}`}
              onClick={() => handleAccordionClick('Orders')}
            >
              <div className="menu-item-text">
                <Icon name="order" />
                {open && 'Orders'}
              </div>
            </Accordion.Header>
            <Accordion.Body
              className={`sub-items ${open ? 'sub-items-open' : ''}`}
            >
              <Nav.Link
                className={`${!open ? 'menu-item' : ''}`}
                data-tooltip="Management"
                onClick={() => navigate('orders')}
                active={location.pathname === '/orders'}
              >
                <div className="menu-item-text">
                  <Icon name="management" />
                  {open && 'Management'}
                </div>
              </Nav.Link>
              <Nav.Link
                className={`${!open ? 'menu-item' : ''}`}
                data-tooltip="Moderation"
                onClick={() => navigate('orders/moderation')}
                active={location.pathname === '/orders/moderation'}
              >
                <div className="menu-item-text">
                  <Icon name="moderation" />
                  {open && 'Moderation'}
                </div>
              </Nav.Link>
              <NavDropdown.Divider />
            </Accordion.Body>
          </Accordion.Item>
        )}
        {checkUserPermissions(USER_PERMISSIONS.SAMPLES) && (
          <Accordion.Item eventKey="SamplePortal" className="item item-hidden">
            <Accordion.Header
              data-tooltip="Sample Portal"
              className={`menu-item ${!open ? 'not-sub-items' : ''}`}
              onClick={() => handleAccordionClick('SamplePortal')}
            >
              <div className="menu-item-text">
                <Icon name="samplePortal" />
                {open && 'Sample Portal'}
              </div>
            </Accordion.Header>
            <Accordion.Body
              className={`sub-items ${open ? 'sub-items-open' : ''}`}
            >
              <Nav.Link
                className={`${!open ? 'menu-item' : ''}`}
                data-tooltip="Editor"
                onClick={() => navigate('sample-portal')}
              >
                <div className="menu-item-text">
                  <Icon name="editor" />
                  {open && 'Editor'}
                </div>
              </Nav.Link>
              <Nav.Link
                className={`${!open ? 'menu-item' : ''}`}
                data-tooltip="History"
                onClick={() => navigate('sample-portal/log')}
              >
                <div className="menu-item-text">
                  <Icon name="history" />
                  {open && 'History'}
                </div>
              </Nav.Link>
              <NavDropdown.Divider />
            </Accordion.Body>
          </Accordion.Item>
        )}
        {checkUserPermissions(USER_PERMISSIONS.PRINTERS) && (
          <Accordion.Item eventKey="printerConfig" className="item">
            <Accordion.Header
              className="not-sub-items setting-item  menu-item"
              data-tooltip="Fulfillment Center"
              onClick={() => {
                handleAccordionClick('printerConfig');
                navigate('printers');
              }}
            >
              <div className="menu-item-text">
                <Icon name="samplePortal" />
                {open && 'Fulfillment Center'}
              </div>
            </Accordion.Header>
          </Accordion.Item>
        )}
        {checkUserPermissions(USER_PERMISSIONS.MYSTORE) && (
          <Accordion.Item eventKey="MyStore" className="item">
            <Accordion.Header
              className="not-sub-items setting-item  menu-item"
              data-tooltip="My Store"
              onClick={() => {
                handleAccordionClick('MyStore');
                navigate('my-store');
              }}
            >
              <div className="menu-item-text">
                <Icon name="myStoryIcon" />
                {open && 'My Store'}
              </div>
            </Accordion.Header>
          </Accordion.Item>
        )}
        {checkUserPermissions(USER_PERMISSIONS.SETTINGS) && (
          <Accordion.Item eventKey="UserManagement" className="item">
            <Accordion.Header
              data-tooltip="User Management"
              className={`not-sub-items setting-item  menu-item`}
              onClick={() => {
                handleAccordionClick('UserManagement');
                navigate('user-management');
              }}
            >
              <div className="menu-item-text">
                <Icon name="user" />
                {open && 'User Management'}
              </div>
            </Accordion.Header>
          </Accordion.Item>
        )}

        <Accordion.Item eventKey="Help" className="item">
          <Accordion.Header
            className="not-sub-items setting-item  menu-item external-link"
            data-tooltip="Help"
            to={HelpCenterURL}
            target="_blank"
            as={Link}
          >
            <div className="menu-item-text">
              <Icon name="help" />
              {open && 'Help'}
            </div>
          </Accordion.Header>
        </Accordion.Item>

        <Accordion.Item eventKey="Account" className="item">
          <Accordion.Header
            className="not-sub-items setting-item  menu-item"
            data-tooltip="Account"
            onClick={() => {
              handleAccordionClick('Account');
              navigate('account');
            }}
          >
            <div className="menu-item-text">
              <Icon name="account" />
              {open && 'Account'}
            </div>
          </Accordion.Header>
        </Accordion.Item>

        <NavDropdown.Divider />

        <Accordion.Item eventKey="Log out" className="item">
          <Accordion.Header
            className="not-sub-items setting-item  menu-item"
            data-tooltip="Log out"
            onClick={() => {
              const urlSearchParamsObj = new URLSearchParams({
                redirect: `${location.pathname}${location.search}`,
              });
              navigate(
                {
                  pathname: `/logout`,
                  search: urlSearchParamsObj.toString(),
                },
                {
                  replace: true,
                },
              );
            }}
          >
            <div className="menu-item-text">
              <Icon name="signOut" />
              {open && 'Log out'}
            </div>
          </Accordion.Header>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default NavigationMenu;
