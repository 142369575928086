import React, {FormEvent, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Auth} from 'aws-amplify';
import {useAuthenticator, View} from '@aws-amplify/ui-react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {AWSAmplifyError} from '@gfxco/contracts';
import PasswordField from '../../components/PasswordField';
import GfxLogo from '../../assets/gfx_logo_beta_black.svg';
import '@aws-amplify/ui-react/styles.css';
import './ForgotPasswordPage.scss';

export default function ForgotPasswordPage() {
  const [isConsentAccepted, setIsConsentAccepted] = React.useState(false);
  const [isPasswordCodeSent, setIsPasswordCodeSent] = React.useState(false);
  const [usernameError, setUsernameError] = React.useState('');
  const [passwordError, setPasswordError] = React.useState('');
  const [passwordCodeError, setPasswordCodeError] = React.useState('');
  const [formError, setFormError] = React.useState('');

  const {route} = useAuthenticator((context) => [context.route]);
  const navigate = useNavigate();

  useEffect(() => {
    if (route === 'authenticated') {
      navigate('/', {replace: true});
    }
  }, [route, navigate]);

  const handleUpdatePassword = async (event: FormEvent) => {
    setPasswordError('');
    setUsernameError('');
    event.preventDefault();
    event.stopPropagation();
    const target = event.target as HTMLFormElement;
    const username = target.username.value;
    const password = target.newPassword.value;
    const passwordCode = target.passwordCode.value;

    if (!password) {
      setPasswordError('Please enter new password');
    }

    if (!username) {
      setUsernameError('Please enter your username');
    }

    if (!passwordCode) {
      setPasswordCodeError('Please enter the password code');
    }

    try {
      await Auth.forgotPasswordSubmit(username, passwordCode, password);
      navigate('/login', {replace: true});
    } catch (error) {
      let message = 'An error occurred. Please try again later.';
      if (error instanceof Error) {
        message = error.message;
      }
      console.error('Error updating password', error);
      setFormError(message);
    }
  };

  const handleRecoverPassword = async (event: FormEvent) => {
    setUsernameError('');
    setFormError('');
    event.preventDefault();
    event.stopPropagation();
    const target = event.target as HTMLFormElement;

    const username = target.username.value;

    if (!username) {
      setUsernameError('Please enter your username');
    }

    try {
      await Auth.forgotPassword(username);
      setIsPasswordCodeSent(true);
    } catch (e) {
      const error = e as AWSAmplifyError;
      console.error('Error sending forgot password action', error);
      const code: string = error.code;
      if (code === 'UserNotFoundException') {
        return setUsernameError('User not found');
      }

      if (
        ['TooManyRequestsException', 'LimitExceededException'].includes(code)
      ) {
        return setFormError(
          'Too many requests, please try again later. If the problem persists, please contact support.',
        );
      }

      setFormError(error.message);
    }
  };

  return (
    <div id="ForgotPasswordPage">
      <View className="auth-wrapper">
        <a className="logo" href="http://www.gfxco.com">
          <img src={GfxLogo} />
        </a>
        <h2 className="sign-in-header">Recover password</h2>
        <Form
          noValidate
          onSubmit={
            isPasswordCodeSent ? handleUpdatePassword : handleRecoverPassword
          }
        >
          <Form.Group controlId="formBasicEmail">
            <Form.Label>
              Email or Username <span className="required-symbol">*</span>
            </Form.Label>
            <Form.Control
              type="email"
              name="username"
              placeholder="Email or Username"
              required
            />
            {usernameError && (
              <p className="form-feedback-error">{usernameError}</p>
            )}
          </Form.Group>
          {isPasswordCodeSent && (
            <>
              <Form.Group controlId="formBasicPassword">
                <Form.Label>
                  Password Code <span className="required-symbol">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="passwordCode"
                  placeholder="Password Code"
                  required
                />
                {passwordCodeError && (
                  <p className="form-feedback-error">{passwordCodeError}</p>
                )}
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Label>
                  New Password <span className="required-symbol">*</span>
                </Form.Label>
                <PasswordField name="newPassword" />
                {passwordError && (
                  <p className="form-feedback-error">{passwordError}</p>
                )}
              </Form.Group>
            </>
          )}
          {formError && <p className="form-feedback-error">{formError}</p>}
          <Button variant="primary" type="submit">
            Recover Password
          </Button>
          <Form.Group controlId="formBasicCheckbox">
            <Link className="back-to-login-link" to="/login">
              Go back to login page
            </Link>
          </Form.Group>
        </Form>
      </View>
      {!isConsentAccepted && (
        <div className="cookie-consent">
          <div className="cookie-consent-text" style={{margin: '15px'}}>
            gfx use cookies to provide necessary site functionalities and
            improve your experience. By using our website, you agree to our
            privacy policy and our cookie policy.
          </div>

          <div className="">
            <button
              className=""
              id="rcc-confirm-button"
              aria-label="Accept cookies"
              style={{
                background: '#f6922d',
                border: '0px',
                fontWeight: 'bold',
                borderRadius: '0px',
                boxShadow: 'none',
                color: 'white',
                cursor: 'pointer',
                padding: '5px 10px',
                margin: '0 15px 15px 15px',
                width: 'calc(100% - 30px)',
              }}
              onClick={() => setIsConsentAccepted(true)}
            >
              ACCEPT
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
