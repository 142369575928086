import React from 'react';
import {ReactComponent as ImageIcon} from '../../assets/icons/upload-image-icon.svg';
import {ReactComponent as ImagePlusIcon} from '../../assets/icons/image-plus-icon.svg';

import './DragImages.scss';

interface RequiredProps {
  dragging: boolean;
}

interface OptionalProps {
  isCompact?: boolean;
}

type DragImagesProps = RequiredProps & OptionalProps;

const DragImages: React.FC<DragImagesProps> = ({
  isCompact = false,
  dragging = false,
}) => {
  return (
    <div id="DragImages" className={`${isCompact ? 'compact-container' : ''}`}>
      <div
        className={`${isCompact ? 'compact-container' : ''} ${
          dragging ? 'dragging' : ''
        } `}
      >
        <div className={isCompact ? 'images-text-compact' : 'images-text'}>
          {!isCompact ? <ImageIcon /> : <ImagePlusIcon />}
          <div className="images-action-text">
            <span>Drag & drop</span>
            <span className="images-action-bold"> your images here</span>
          </div>
        </div>

        <div className="images-action-text note-text">
          <span>{`${
            isCompact ? 'Drop' : 'Remember, you can upload'
          } any picture from`}</span>
          <span className="images-action-bold">{` 100px ${
            !isCompact ? 'by 100px' : ''
          }`}</span>
          <span> to </span>
          <span className="images-action-bold">{`6000px ${
            !isCompact ? 'by 6000px' : ''
          }`}</span>
          <span> We suggest using high resolution images of</span>
          <span className="images-action-bold"> 300 DPI.</span>
        </div>
      </div>
    </div>
  );
};

export default DragImages;
