import {createListenerMiddleware} from '@reduxjs/toolkit';
import {
  getShopConfigsAsync,
  getShopSubscriptionsAsync,
  updateSelectedShop,
} from '../../features/shops/selectedShop';
import {getServicesAsync} from '../../features/services/loadServices';
import {reloadAllClients} from '../../api';
import {getUserPermissionsAsync} from '../../features/user';
import {resetIntegrationStatus as resetShopifyIntegration} from '../../features/integrations/loadShopifyIntegration';

const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  actionCreator: updateSelectedShop,
  effect: async (action, listenerApi) => {
    const shopId = action && action.payload && action.payload.id;

    // Can cancel other running instances
    listenerApi.cancelActiveListeners();

    reloadAllClients();

    if (shopId) {
      listenerApi.dispatch(resetShopifyIntegration());
      listenerApi.dispatch(getUserPermissionsAsync());
      listenerApi.dispatch(getShopConfigsAsync(shopId));
      listenerApi.dispatch(getShopSubscriptionsAsync(shopId));
      listenerApi.dispatch(getServicesAsync());
    }
  },
});

export default listenerMiddleware.middleware;
