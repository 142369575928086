import React from 'react';
import Icon from '../../Icons/Icon';
import {useGFXInstance} from '@best-apps/gfx-editor';
import InchesSizeInput from '../../InchesSizeInput/InchesSizeInput';
import FontsPicker from '../../FontsPicker/FontsPicker';
import {GFXFont} from '@best-apps/gfx-editor/lib/package/dist/types/v1Template';
import FontsColorPicker from '../../FontsColorPicker/FontsColorPicker';
import FontSizePicker from '../../FontSizePicker/FontSizePicker';

type TopToolbarFontsProps = {
  activateSaveWarning: () => void;
};

const TopToolbarFonts: React.FC<TopToolbarFontsProps> = (props) => {
  const gfx = useGFXInstance();
  if (!gfx) return null;
  const [activeObject, setActiveObject] = React.useState<
    typeof gfx.state.activeObject
  >({});
  const [userInputWidth, setUserInputWidth] = React.useState(0);
  const [userInputHeight, setUserInputHeight] = React.useState(0);
  const [isSelectingFont, setIsSelectingFont] = React.useState(false);
  const [isSelectingColor, setIsSelectingColor] = React.useState(false);
  const [isSelectingSize, setIsSelectingSize] = React.useState(false);
  const [availableFonts, setAvailableFonts] = React.useState<GFXFont[]>([]);
  const [fillColor, setFillColor] = React.useState<string>('blue');

  const fonts = gfx.state.initialData.v2Template.fonts;
  const showToolbar = activeObject && activeObject.type === 'textbox';
  const currentFont = showToolbar ? activeObject.fontFamily : '';
  const fontSize = showToolbar ? activeObject.fontSize : '';
  const colorFill = showToolbar ? activeObject.fill : '';

  const disabledAlign =
    activeObject?.type !== 'textbox' || gfx?.state?.activeObject?.isBackground;

  // EFFECTS
  React.useEffect(() => {
    if (!showToolbar) {
      setIsSelectingFont(false);
    }
  }, [showToolbar]);

  React.useEffect(() => {
    // Effect to catch the active object
    if (!gfx) return;
    if (gfx && gfx.state.activeObject) {
      const activeObject = gfx.state.activeObject;
      setFillColor('#4573BB');
      return setActiveObject(activeObject);
    }

    if (!gfx.state.activeObject) {
      setFillColor('#838383');
      setUserInputWidth(0);
      setUserInputHeight(0);
      setActiveObject({});
    }
  }, [gfx, gfx?.state?.activeObject, gfx?.state?.activeObject?.src]);

  React.useEffect(() => {
    // Effect to update sizes internally
    if (activeObject.uuid) {
      GFXSizes();
      setIsSelectingColor(false);
      setIsSelectingSize(false);
      setIsSelectingFont(false);
    }
  }, [
    gfx?.state.activeObject,
    gfx?.state.activeObject?.width,
    gfx?.state.activeObject?.height,
    gfx?.state.activeObject?.scaleX,
    gfx?.state.activeObject?.scaleY,
    gfx?.state.activeObject?.scale,
    gfx?.state.activeObject?.uuid,
  ]);

  React.useEffect(() => {
    if (!gfx || !fonts) return;

    fonts.forEach((font) => {
      checkExist(font.urlFont).then((exist) => {
        if (!exist) return;

        setAvailableFonts((prev) => {
          if (prev.find((f) => f.fontFamily === font.fontFamily)) return prev;
          return [...prev, font];
        });
      });
    });
  }, [fonts]);

  const checkExist = async (url: string) => {
    try {
      await fetch(url, {method: 'HEAD'});
      return true;
    } catch (error) {
      return false;
    }
  };

  const onFontSizeChange = (size: number) => {
    if (!gfx) return null;

    gfx.actions.setFontSizeOnTextbox(size);
    props.activateSaveWarning();
    setIsSelectingSize(false);
  };

  const onColorChange = async (color: string) => {
    if (!gfx) return;
    await gfx.actions.setFillColorOnTextbox(color);
    props.activateSaveWarning();
    setIsSelectingColor(false);
  };

  const GFXSizes = async () => {
    const size = await gfx?.actions.getInchesSize();
    if (size) {
      setUserInputWidth(+size.width.toFixed(1));
      setUserInputHeight(+size.height.toFixed(1));
    }
  };

  const onFontClick = async (font: GFXFont) => {
    if (!gfx) return;

    await gfx.actions.setFontFamilyOnTextbox(font.fontFamily, {
      fontId: font.id,
    });
    props.activateSaveWarning();
    setIsSelectingFont(false);
  };

  if (!showToolbar) return null;
  const setIsSelectingFontClick = () => {
    setIsSelectingColor(false);
    setIsSelectingSize(false);
    setIsSelectingFont(!isSelectingFont);
  };
  const setIsSelectingColorClick = () => {
    setIsSelectingFont(false);
    setIsSelectingSize(false);
    setIsSelectingColor(!isSelectingColor);
  };

  const setIsSelectingSizeClick = () => {
    setIsSelectingFont(false);
    setIsSelectingColor(false);
    setIsSelectingSize(!isSelectingSize);
  };

  const onSizeKeyPressed = async (
    evt: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    const keyPressed = evt.key;
    if (keyPressed === 'Enter') {
      await gfx?.actions.setInchesSize(userInputWidth, userInputHeight);
      props.activateSaveWarning();
    }
  };

  const onTextSizeChange = async (
    key: string,
    evt: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!activeObject || !activeObject.uuid || activeObject.isBackground) {
      return console.log('no active object found');
    }

    const target = evt.target as HTMLInputElement;
    const value = target.value;

    if (key === 'width') {
      target.value = value;
      return setUserInputWidth(+value);
    }
  };

  const handleTextAlign = async (align: 'left' | 'center' | 'right') => {
    if (gfx && gfx.state.activeObject) {
      gfx.actions.setTextAlign(align);
      props.activateSaveWarning();
    }
  };

  return (
    <div className="gfx-builder-toolbar top-builder-toolbar toolbar-text">
      <div className="top-builder-toolbar__description-ico">
        <Icon name="textSlotIcon" />
      </div>
      <div
        className="toolbar_box_button top-builder-toolbar__fonts-picker-button"
        onClick={setIsSelectingFontClick}
      >
        <p>{currentFont || 'Current Font'}</p>
        {isSelectingFont && (
          <FontsPicker fonts={availableFonts} onClickFont={onFontClick} />
        )}
        <Icon classes="toolbar-icon icon-left" name="downArrow" />
      </div>
      <div
        className="toolbar_box_button top-builder-toolbar__fonts-color-picker-button"
        onClick={setIsSelectingColorClick}
      >
        <div
          className="preview_box"
          style={{
            backgroundColor: colorFill,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        ></div>
        <p>{colorFill}</p>
        <Icon classes="toolbar-icon icon-left" name="downArrow" />
        {isSelectingColor && <FontsColorPicker onColorClick={onColorChange} />}
      </div>
      <div
        className="toolbar_box_button top-builder-toolbar__fonts-size-picker"
        onClick={setIsSelectingSizeClick}
      >
        <p>{fontSize}</p>
        <Icon name="downArrow" classes="icon-left toolbar-icon" />
        {isSelectingSize && (
          <FontSizePicker
            count={90}
            currentSize={fontSize}
            startCount={10}
            onSizeClick={onFontSizeChange}
          />
        )}
      </div>
      <div className="font-icon-group">
        <Icon
          action={async () => await handleTextAlign('left')}
          box
          tooltip="Align Left"
          name="alignLeft"
          disabled={disabledAlign}
          fillColor={{default: fillColor}}
        />
        <Icon
          action={async () => await handleTextAlign('center')}
          box
          tooltip="Align Center"
          name="alignCenter"
          disabled={disabledAlign}
          fillColor={{default: fillColor}}
        />
        <Icon
          action={async () => await handleTextAlign('right')}
          box
          tooltip="Align Right"
          name="alignRight"
          disabled={disabledAlign}
          fillColor={{default: fillColor}}
        />
      </div>
      <InchesSizeInput
        height={userInputHeight}
        width={userInputWidth}
        disableHeight={true}
        onKeyDown={onSizeKeyPressed}
        onChange={onTextSizeChange}
      />
    </div>
  );
};

export default TopToolbarFonts;
