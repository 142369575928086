import React from 'react';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';
import ProductModalHeader from '../ProductModalHeader';
import {useAppSelector} from '../../app/hooks';
import {selectShop} from '../../features/shops/selectedShop';
import GFXImage from '../Image';
import {
  ProductTypeDBModel,
  INextStep,
  ProductTypeRequest,
} from '@gfxco/contracts';
import {getProductTypes} from '../../api';
import CloseConfirmationModal from '../CloseConfirmationModal';

import './SelectProductType.scss';

interface OptionalProps {
  initialItemSelected?: string;
}

interface RequiredProps {
  onNextStep: (params: INextStep) => void;
}

const TITLE = 'Select your product type';
const INPUT_NAME = 'Search our catalog';
type SelectProductTypeProps = RequiredProps & OptionalProps;

export const SelectProductType: React.FC<SelectProductTypeProps> = ({
  onNextStep,
  initialItemSelected,
}) => {
  const selectedShop = useAppSelector(selectShop);
  const shopId = selectedShop!.id;
  const [isLoading, setIsLoading] = React.useState(false);
  const [productTypes, setProductTypes] = React.useState<ProductTypeDBModel[]>(
    [],
  );
  const [isError, setIsError] = React.useState(false);
  const [showProductTypeConfirmation, setShowProductTypeConfirmation] =
    React.useState(false);
  const [chosenProductType, setChosenProductType] = React.useState<
    ProductTypeDBModel | undefined
  >(undefined);

  const handleOnClick = (productType: ProductTypeDBModel) => {
    setChosenProductType(productType);
    if (initialItemSelected && initialItemSelected !== productType.code) {
      setShowProductTypeConfirmation(true);
      return;
    }
    handleNextClick(productType);
  };

  const handleNextClick = (productType?: ProductTypeDBModel) => {
    if (productType) {
      const nextStep = {step: 3, data: {productType: productType.code}};
      onNextStep(nextStep);
      setShowProductTypeConfirmation(false);
    }
  };

  React.useEffect(() => {
    if (!isLoading) {
      getProductTypesData();
    }
  }, []);

  const getProductTypesData = async () => {
    try {
      if (!shopId) return;
      setIsLoading(true);
      const params: ProductTypeRequest = {
        shopId,
      };
      const productTypes = await getProductTypes(params);
      if (productTypes) {
        setProductTypes(productTypes);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsError(true);
    }
  };

  if (isError) {
    return <div>ERRRORRRR.</div>;
  }

  return (
    <div className="SelectProductType">
      <ProductModalHeader title={TITLE} inputName={INPUT_NAME} />
      {!isLoading && (
        <div className="SelectProductType__Container">
          {productTypes.map((item) => (
            <div
              key={`${item.name}`}
              className={classNames('SelectProductType__item', {
                SelectProductType__Selected: initialItemSelected === item.code,
              })}
            >
              <div
                className="SelectProductType__Image"
                onClick={() => handleOnClick(item)}
              >
                <GFXImage
                  src={item.thumbnail_url}
                  placeholderSrc="/loading-render.gif"
                />
              </div>
              <div className="SelectProductType__ImageText">
                <span>{item.name}</span>
              </div>
            </div>
          ))}
        </div>
      )}
      {isLoading && (
        <Skeleton
          className="SelectProductType__item skeleton"
          containerClassName="SelectProductType__Container"
          count={6}
          inline
        />
      )}
      <CloseConfirmationModal
        show={showProductTypeConfirmation}
        onContinue={() => handleNextClick(chosenProductType)}
        onClose={() => setShowProductTypeConfirmation(false)}
        title="Are you sure you want to change your product type"
        text="If you change the product type will reset your current template configuration. Are you sure you want to proceed?"
        continueText="Yes, change product type"
        closeText="No, and close window"
      />
    </div>
  );
};
export default SelectProductType;
