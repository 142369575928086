import {ISize} from '@gfxco/contracts';

interface DefaultSizes {
  [key: string]: string;
}

const DEFAULT_SIZES: DefaultSizes = {
  XS: 'XS',
  S: 'S',
  M: 'M',
  L: 'L',
  XL: 'XL',
  '2XL': 'XXL',
  '3XL': 'XXXL',
  '4XL': 'XXXXL',
  '5XL': 'XXXXXL',
  '': '-',
};

const mapSizes = (sizes: ISize[], productType?: string): string | string[] => {
  if (!sizes.length) {
    return 'None';
  }

  if (productType === 'framedPoster') {
    return `${sizes.length} sizes available`;
  }

  const firstSize = DEFAULT_SIZES[sizes.at(0)?.code || ''];
  const lastSize = DEFAULT_SIZES[sizes.at(-1)?.code || ''];
  return [firstSize, lastSize];
};

export default mapSizes;
