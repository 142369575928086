import React from 'react';

import Card from 'react-bootstrap/Card';
import ProductModalHeader from '../ProductModalHeader';
import Icon from '../Icons/Icon';
import {INextStep} from '@gfxco/contracts';

import './SetDesignType.scss';
import classNames from 'classnames';

interface RequiredProps {
  onNextStep: (params: INextStep) => void;
}

interface OptionalProps {
  onInputChange?: (name: string) => void;
  initialProductName?: string;
}

const TITLE = 'Name your design and select your design method';
const INPUT_NAME = 'Enter design name:';

type SetDesignTypeProps = RequiredProps & OptionalProps;

export const SetDesignType: React.FC<SetDesignTypeProps> = ({
  onNextStep,
  onInputChange,
  initialProductName = '',
}) => {
  const [productName, setProductName] = React.useState(initialProductName);

  React.useEffect(() => {
    if (productName === '') {
      setProductName(initialProductName);
    }
  }, [initialProductName]);

  const handleInputChange = (value: string) => {
    setProductName(value);
    if (onInputChange) {
      onInputChange(value);
    }
  };

  const handleOnClick = () => {
    if (productName.length) {
      const nextStep = {step: 2, data: {productName}};
      onNextStep(nextStep);
    }
  };

  const disableChooseProductType = !productName.length;

  return (
    <div className="SetDesignType">
      <ProductModalHeader
        title={TITLE}
        inputName={INPUT_NAME}
        handleInputChange={handleInputChange}
        centered
        inputDefaultValue={productName}
      />
      <h4>Select an option:</h4>
      <div className="SetDesignType__Container">
        <div
          className={classNames(
            'SetDesignType__Card',
            'SetDesignType__Card__ChooseProductType',
            {disabled: disableChooseProductType},
          )}
          onClick={handleOnClick}
        >
          <Icon name="chooseProductType" />
          <h5>Choose a product type</h5>
        </div>
        <div className="SetDesignType__Card CreateCustomCanvaOption disabled">
          <Card.Body>
            <Icon name="createCustomCanva" />
            <h5>Create Your Custom Design Canvas</h5>
          </Card.Body>
        </div>
      </div>
    </div>
  );
};
export default SetDesignType;
