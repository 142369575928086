import React from 'react';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import {ReactComponent as FolderIcon} from '../../../assets/icons/folder-icon.svg';
import usePagination from '../../../hooks/usePagination';
import useQueryParams from '../../../hooks/useQueryParams';
import CollectionBreadcrumb from '../../../components/ImageDashboard/CollectionBreadcrumb';
import PreviewSection from '../../../components/ImageDashboard/PreviewSection';
import {
  getImagesAsync,
  resetImagesPages,
  getImagesByPage,
  selectTotalImages,
  fetchStatusByPage,
} from '../../../features/images/loadImages';
import CustomPagination from '../../../components/CustomPagination';
import ImageBanner from '../../../components/ImageBanner';
import {IImageRequest, ELoadingStates} from '@gfxco/contracts';
import {ChevronDown, ChevronUp} from 'react-bootstrap-icons';
import './ViewSubcollection.scss';

interface RequiredProps {
  collectionName: string;
  onDeleteCollection: (id: number) => void;
  onDeleteImage?: (id: number) => void;
  name: string;
}

interface OptionalProps {
  id?: number;
  shopId?: number;
  collectionId?: number;
}

type ViewSubcollectionProps = RequiredProps & OptionalProps;

const ViewSubcollection: React.FC<ViewSubcollectionProps> = ({
  collectionName,
  name,
  shopId,
  id,
  collectionId,
  onDeleteCollection,
  onDeleteImage,
}) => {
  const [queryParams] = useQueryParams();
  const sortBy = queryParams.sortBy || 'newest';
  const search = queryParams.search || '';
  const preview = queryParams.preview || 'gallery';
  const [{offset, currentPage, limit}, handlePagination] = usePagination();
  const [showBanner, setShowBanner] = React.useState(true);

  const images = useAppSelector((state) => getImagesByPage(state, currentPage));
  const totalImages = useAppSelector((state) => selectTotalImages(state));
  const imagesStatus = useAppSelector((state) =>
    fetchStatusByPage(state, currentPage),
  );
  const [open, setOpen] = React.useState(true);
  const dispatch = useAppDispatch();
  const collectionNameEncoded = encodeURIComponent(collectionName);

  React.useEffect(() => {
    if (imagesStatus === ELoadingStates.IDLE && id) {
      const params: IImageRequest & {page: number} = {
        shopId,
        offset,
        limit,
        page: currentPage,
        collections: [+id],
        order: sortBy === 'newest' ? 'desc' : 'asc',
      };
      if (search) params.name = search;
      dispatch(getImagesAsync(params));
    }
  }, [shopId, offset, imagesStatus, limit, id]);

  React.useEffect(() => {
    handlePagination('reset');
    dispatch(resetImagesPages());
  }, [queryParams.sortBy, queryParams.search, id, shopId]);

  if (imagesStatus === ELoadingStates.FAILED) {
    return <div>Error loading images!.</div>;
  }

  return (
    <div id="ViewSubcollection">
      <div>
        <CollectionBreadcrumb
          items={[
            {
              name: 'All collections',
              navigation: '/designs/collections',
              icon: <FolderIcon />,
            },
            {
              name: collectionName,
              navigation: `/designs/collections/${collectionNameEncoded}?collectionId=${collectionId}`,
            },
            {
              name,
              navigation: `/designs/collections/${collectionNameEncoded}/${encodeURIComponent(
                name,
              )}?collectionId=${collectionId}`,
            },
          ]}
        />
      </div>
      <ImageBanner show={showBanner} onClose={() => setShowBanner(false)} />
      <div className="subcollection-box">
        <div className="collapse-box">
          <a className="link" onClick={() => setOpen(!open)}>
            {!open ? (
              <ChevronDown className="icon" />
            ) : (
              <ChevronUp className="icon" />
            )}
            <span>Sub-collections</span>
          </a>
        </div>
        {open && (
          <PreviewSection
            onDeleteImage={onDeleteImage}
            onDeleteCollection={onDeleteCollection}
            type="images"
            list={images}
            handleOnClick={(name: string) => {}}
            isLoading={imagesStatus !== ELoadingStates.LOADED}
            listView={preview === 'list'}
          />
        )}
      </div>
      <CustomPagination
        limit={limit}
        currentPage={currentPage}
        total={totalImages}
        handlePagination={handlePagination}
        entity="Images"
      />
    </div>
  );
};

export default ViewSubcollection;
