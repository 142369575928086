import React, {useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import classNames from 'classnames';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import {Trash, XLg, Pencil} from 'react-bootstrap-icons';
import {useAppSelector} from '../../../app/hooks';
import {ReactComponent as ImageIcon} from '../../../assets/icons/upload-image-icon.svg';
import {ReactComponent as ImageFailIcon} from '../../../assets/icons/image-failed-icon.svg';
import {selectShop} from '../../../features/shops/selectedShop';
import {selectCollections} from '../../../features/collections/loadCollectionsDetails';
import {addCollection, editCollection} from '../../../api';
import imagesApi from '../../../api/images';
import FileInput from '../../FileInput';
import {
  IAddCollectionRequest,
  IImage,
  ICollectionResponse,
  IImageRequest,
  IEditCollectionRequest,
} from '@gfxco/contracts';
import GFXModals from '../../Modal';
import DragImages from '../../DragImages';
import GFXImage from '../../Image/Image';
import Loading from '../../Loading';
import uploadImage, {
  validateFileLimits,
  IImageFile,
  allowedImageExtensionsTypes,
  updateFileName,
} from '../../../libs/uploadImage';
import {Row, Col} from 'react-bootstrap';
import CollectionItem from '../CollectionItem';

import './CollectionsWizard.scss';

interface RequiredProps {
  open: boolean;
  onClose: () => void;
  onDeleteImage: (id: number) => void;
}

interface OptionalProps {
  parentId?: number;
  collectionDraft?: ICollectionResponse;
  onSucess?: () => void;
  confirmImageDeleted?: number;
}

type CollectionsWizardProps = RequiredProps & OptionalProps;

const DEFAULT_LIMITS = {
  width: 6000,
  height: 6000,
  weight: 50,
};

const CollectionsWizard: React.FC<CollectionsWizardProps> = ({
  open,
  onClose,
  onDeleteImage,
  parentId,
  collectionDraft,
  onSucess,
  confirmImageDeleted,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const shopSelected = useAppSelector(selectShop);
  const shopId = shopSelected?.id;
  const [name, setName] = React.useState<string>('');
  const collections = useAppSelector(selectCollections);
  const collection = collections.find(
    (collection) => collection.id === parentId,
  );
  const [description, setDescription] = React.useState<string>('');
  const [showErrorMessage, setShowErrorMessage] = React.useState('');
  const [showEmptyWarning, setShowEmptyWarning] = React.useState(false);
  const [showDpiWarning, setShowDpiWarning] = React.useState(true);
  const [emptyMessageWarning, setEmptyMessageWarning] = React.useState('');
  const [imagesSelected, setImagesSelected] = React.useState<IImage[]>([]);
  const [imagesToUpload, setImagesToUpload] = React.useState<File[]>([]);
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [isLoadingImages, setIsLoadingImages] = React.useState<boolean>(false);
  const [isError, setIsError] = React.useState(false);
  const label = parentId ? 'sub-collection' : 'Collection';
  // Drag and drop
  const [dragging, setDragging] = React.useState(false);

  useEffect(() => {
    setName(collectionDraft ? collectionDraft.name : '');
    setDescription(collectionDraft ? collectionDraft.description : '');
    getImages();
  }, [collectionDraft]);

  useEffect(() => {
    if (confirmImageDeleted) {
      setImagesSelected(
        imagesSelected.filter((image) => image.id !== confirmImageDeleted),
      );
    }
  }, [confirmImageDeleted]);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
    const files = event.dataTransfer?.files;
    await onNewFiles(files!);
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
  };

  const onNewFiles = async (fileList: FileList) => {
    const imagesLoaded: IImageFile[] = [];
    const files = Array.from(fileList);
    for (const file of files) {
      try {
        const image = await handleFileChange(file);
        if (!image) continue;
        imagesLoaded.push({file, name: image.name});
      } catch (error: any) {
        imagesLoaded.push({
          isError: true,
          name: file.name,
          error: error.message!,
        });
      }
    }

    handleImageUploaded(imagesLoaded);
  };

  const getImages = async () => {
    setIsLoadingImages(true);
    try {
      if (collectionDraft && collectionDraft.id) {
        const params: IImageRequest = {
          shopId,
          collections: [collectionDraft.id],
        };
        const images = await imagesApi.getImages(params);
        if (images) setImagesSelected([...imagesSelected, ...images?.results]);
      }
    } catch (error) {
      console.error(error);
      setIsLoadingImages(false);
    } finally {
      setIsLoadingImages(false);
    }
  };

  const addCollections = async (images: IImage[]) => {
    if (shopId && name && images.length) {
      const params: IAddCollectionRequest = {
        shopId,
        name,
        description,
        images,
        parentId,
      };
      await addCollection(params);
    }
  };

  const editCollections = async (images: IImage[]) => {
    if (shopId && name && collectionDraft?.id) {
      const params: IEditCollectionRequest = {
        name,
        description,
        images,
        shopId,
        id: collectionDraft.id,
      };
      await editCollection(params);
      if (onSucess) onSucess();
    }
  };

  const handleOnSave = async () => {
    try {
      if (!name.length) {
        if (collectionDraft) {
          setEmptyMessageWarning(
            'Any collection should have a name, please write a new name for your collection',
          );
        } else {
          setEmptyMessageWarning(
            `You must give your ${label.toLowerCase()} a name`,
          );
        }
        return setShowEmptyWarning(true);
      }

      if (!imagesToUpload.length && !collectionDraft) {
        setEmptyMessageWarning(`You must add an image to create your ${label}`);
        return setShowEmptyWarning(true);
      }

      setIsSaving(true);
      const images = await handleAddImages();
      try {
        if (collectionDraft && collectionDraft.id) {
          await editCollections(images);
        } else {
          await addCollections(images);
        }
        onClose();
        handleOnClose();
      } catch (error) {
        console.error(error);
        if (typeof error === 'string' && error.includes('already exists'))
          setShowErrorMessage(
            `Looks like the name ${name} is already attached to another collection, Please try a new one`,
          );
        setIsError(true);
      }
    } catch (error) {
      setIsSaving(false);
      setIsError(true);
      console.log({error});
      setShowErrorMessage('Error creating a new collection!');
    } finally {
      setIsSaving(false);
    }
  };

  const handleOnClose = () => {
    setImagesSelected([]);
    setImagesToUpload([]);
    setName('');
    setDescription('');
    setIsError(false);
    setIsSaving(false);
    setShowErrorMessage('');
    setShowDpiWarning(true);
    if (location.pathname.startsWith('/designs/images')) {
      navigate('/designs/images');
    } else {
      navigate('/designs/collections');
    }
    onClose();
  };

  const handleFileChange = async (file: File) => {
    if (!shopId) return;
    return validateFileLimits({
      file,
      limits: DEFAULT_LIMITS,
      fileName: file.name,
      shopId,
    });
  };

  const handleImageUploaded = (imagesFile: IImageFile[]) => {
    const imagesErrored = imagesFile.filter((item) => !!item.isError);

    const imagesLoaded: File[] = [];
    const images = imagesFile
      .filter((item) => !item.isError)
      .map((item) => {
        let imageUrl = '';
        let newFileName = item.name;
        if (item.file) {
          newFileName = updateFileName(item.file.name, item.file.type);
          item.file.newFileName = newFileName;
          imageUrl = URL.createObjectURL(item.file);
          imagesLoaded.push(item.file);
        }
        return {name: newFileName, imageUrl};
      });

    setImagesSelected((prev) => [...prev, ...imagesErrored, ...images]);
    setImagesToUpload((prev) => {
      return [...prev, ...imagesLoaded];
    });
  };

  const handleAddImages = async () => {
    const imageUploadPromises = imagesToUpload.map((file) => {
      const newFileName = updateFileName(file.name, file.type);
      return uploadImage({
        file,
        fileName: newFileName,
        shopId: shopId!,
        fileFolder: 'images',
        limits: DEFAULT_LIMITS,
      });
    });

    const results = await Promise.allSettled(imageUploadPromises);

    return results
      .filter((result) => result.status === 'fulfilled')
      .map((item: any) => item.value);
  };

  const handleOnDeleteImage = (id: number) => {
    onDeleteImage(id);
  };

  const handleOnChangeName = (value: string) => {
    const validInput = value.replace(/[^a-zA-Z0-9:#\s-]+/g, '');
    setName(validInput);
  };

  const removeImageFromList = (name: string) => {
    setImagesSelected((old) => {
      return old.filter((item) => item.name !== name);
    });
    setImagesToUpload((old) => {
      return old.filter(
        (item: IImageFile['file']) => item?.newFileName !== name,
      );
    });
  };

  return (
    <GFXModals.SimpleModal
      className="modal-middle-page modal-middle-colored"
      isSaveEnabled={!!name.length && !!imagesSelected.length}
      modalTile={`${!collectionDraft ? 'Create' : 'Edit'} a ${label}`}
      onCloseModal={handleOnClose}
      show={open}
    >
      <div id="CollectionsWizard">
        <div className="body">
          <div className="info-containers">
            {parentId && collection && (
              <div className="info-parent-selected">
                <span className="info-container-title">
                  Your parent collection:
                </span>
                <CollectionItem
                  id={collection.id}
                  name={collection.name}
                  description={collection.description}
                  imageUrl={collection.imageUrl}
                  largeImageUrl={collection.largeImageUrl}
                  active={false}
                  onDeleteCollection={() => null}
                  locked
                />
              </div>
            )}
            <div
              className={`input-flex-box ${parentId ? 'input-col-box' : ''}`}
            >
              {parentId && (
                <span className="info-container-title">
                  Your sub-collection information:
                </span>
              )}
              <div
                className={`input-name ${
                  showEmptyWarning ? 'input-warning-name' : ''
                }`}
              >
                {!parentId && (
                  <span className="selector-text">{`Your ${label} name:*`}</span>
                )}
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder={`Name your ${label}*`}
                    onChange={(event: any) =>
                      handleOnChangeName(event.target.value)
                    }
                    value={name}
                  />
                  {!!collectionDraft && <Pencil />}
                </InputGroup>
                {(isError || !!collectionDraft) &&
                  (showErrorMessage || showEmptyWarning) && (
                    <div className="warning-tooltip">
                      <span>{showErrorMessage || emptyMessageWarning}</span>
                      <span
                        className="warning-tooltip-link"
                        onClick={() => {
                          setShowErrorMessage('');
                          setShowEmptyWarning(false);
                        }}
                      >
                        Got it!
                      </span>
                    </div>
                  )}
              </div>
              <div className="input-name">
                {!parentId && (
                  <span className="selector-text">Description:</span>
                )}
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder={`Write a description for your ${label}`}
                    onChange={(event: any) =>
                      setDescription(event.target.value)
                    }
                    value={description}
                  />
                  {!!collectionDraft && <Pencil />}
                </InputGroup>
              </div>
            </div>
          </div>

          <div className="upload-container">
            <div className="icon-container">
              <div className="image-trash-button" data-tooltip="Add images">
                <FileInput
                  text={''}
                  onFileChange={handleFileChange}
                  onFileLoaded={handleImageUploaded}
                  icon={<ImageIcon />}
                  omitUpload
                />
              </div>

              <button className="image-trash-button" disabled>
                <Trash color="#D2D2D2" />
              </button>
            </div>

            <div
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              onDragLeave={handleDragLeave}
              onDragEnter={handleDragEnter}
              className={`images-container ${
                !imagesSelected.length
                  ? 'images-empty-container'
                  : showEmptyWarning
                  ? 'images-warnign-container'
                  : ''
              }`}
            >
              {showDpiWarning && (
                <div className="banner-warning">
                  <b>Designer tip:</b>
                  Make sure to use the high resolution images.
                  <a>For printed articles, the recommendation is 300 DPI.</a>
                  <XLg onClick={() => setShowDpiWarning(false)} />
                </div>
              )}
              {isLoadingImages && !imagesSelected.length && (
                <Loading spinnerOnly />
              )}
              {!isLoadingImages && imagesSelected.length ? (
                <Row
                  className={classNames({
                    'image-list': true,
                    'image-list-with-warning': showDpiWarning,
                  })}
                  tabIndex={0}
                >
                  {imagesSelected.map((image, index) => (
                    <Col
                      key={`${image.id!}-${index}`}
                      className="image-item"
                      md="auto"
                    >
                      {image.imageUrl && (
                        <div className="delete-icon-image">
                          <button
                            className="icon-fill"
                            onClick={() => {
                              if (!image.id && image.name) {
                                return removeImageFromList(image.name);
                              }
                              handleOnDeleteImage(image.id!);
                            }}
                          >
                            <XLg />
                          </button>
                          <GFXImage
                            src={image.imageUrl}
                            placeholderSrc="/loading-render.gif"
                          />
                        </div>
                      )}
                      {image.isError && (
                        <div className="error-message" title={image.error}>
                          <ImageFailIcon />
                          <span className="error-message-warning">Oops!</span>
                          <span className="error-message-text">
                            {image.error?.includes('extensions')
                              ? 'The image does not meet the requirements.'
                              : 'It seems like we’re having some difficulties'}
                          </span>
                          <span
                            className="error-message-more-info"
                            data-tooltip={
                              image.error?.includes('extensions')
                                ? `Allowed extensions: ${allowedImageExtensionsTypes.join(
                                    ', ',
                                  )}`
                                : "We couldn't upload your image, please check our size requirements and try again"
                            }
                          >
                            see why
                          </span>
                        </div>
                      )}
                      {image.isLoading && (
                        <div className="error-message">
                          <Loading spinnerOnly />
                        </div>
                      )}
                      <span className="image-name">{image.name}</span>
                      <div className="image-item-actions">
                        <Form.Check
                          name={`check-image-${image.id}`}
                          type="checkbox"
                          checked={false}
                          onChange={(e) => {}}
                        />
                        <button className="icon-fill" disabled>
                          <XLg />
                        </button>
                      </div>
                    </Col>
                  ))}
                </Row>
              ) : (
                ''
              )}

              <DragImages
                isCompact={!!imagesSelected.length}
                dragging={dragging}
              />
            </div>
          </div>
          <div className="upload-action">
            <button
              className="create-button"
              disabled={isSaving}
              onClick={() => handleOnSave()}
            >
              {isSaving
                ? 'Loading...'
                : collectionDraft
                ? 'Save Changes'
                : `Create ${label}`}
            </button>
          </div>
          {!collectionDraft && showEmptyWarning && (
            <div className="warning-tooltip">
              <span>{emptyMessageWarning}</span>
              <span
                className="warning-tooltip-link"
                onClick={() => setShowEmptyWarning(false)}
              >
                Got it!
              </span>
            </div>
          )}
        </div>
      </div>
    </GFXModals.SimpleModal>
  );
};

export default CollectionsWizard;
