import {Link} from 'react-router-dom';
import GFXTooltip from '../Tooltip/Tooltip';

interface CollectionDeleteNotificationProps {
  dependences: number[];
  type: 'collection' | 'image' | 'sub-collection';
  onClose: () => void;
}

const DeleteImageCollectionWarn = (
  props: CollectionDeleteNotificationProps,
) => {
  const Dependencies = () => {
    const urlForImages = '/designs/edit';
    const urlForCollections = '/designs/controls';
    const url = ['collection', 'sub-collection'].includes(props.type)
      ? urlForCollections
      : urlForImages;
    const dependencesToShow = props.dependences.slice(0, 5);
    return (
      <ul>
        {dependencesToShow.map((dep, index) => (
          <li key={index}>
            <Link to={`${url}/${dep}`}># {dep}</Link>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <GFXTooltip
      onAccept={props.onClose}
      onClose={props.onClose}
      acceptText="Got it"
      width="45rem"
      height="auto"
      top="25%"
      left="40%"
    >
      <div className="delete-image-collection-warning">
        <div className="title">
          <h2>Hold On!</h2>
        </div>
        <div className="content">
          <p>
            Looks like the {props.type} you are trying to delete is already
            being used in the following templates:
          </p>
          <Dependencies />
          <p>
            To continue, <b>remove from Slots.</b> The {props.type}(s) from the
            template(s) mentiones adove must be removed before they can be
            deleted. These images cannot be the “main image” of an existing
            design otherwise ther cannot be deleted
          </p>
          <p>
            <b>Attention:</b> This action cannot be undone. Deleting images
            images could make changes to your existing templates, stickers,
            exceptions and linked slots.
          </p>
        </div>
      </div>
    </GFXTooltip>
  );
};

export default DeleteImageCollectionWarn;
