import {IGetFulfillmentServices} from '@gfxco/contracts';
import {handleError, loadClient} from './api';

export const getFulfillmentServices = async (shopId: number) => {
  try {
    const client = await loadClient();
    const response = await client.get<IGetFulfillmentServices>(
      `/fulfillment-services`,
      {
        params: {
          shopId,
        },
      },
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};
