import React from 'react';
import Table from 'react-bootstrap/Table';
import {useAppSelector} from '../../app/hooks';
import {selectShop} from '../../features/shops/selectedShop';
import OrderRow from './OrderRow/OrderRow';
import Loading from '../../components/Loading';
import CustomPagination from '../../components/CustomPagination';
import TableOrderFilter from './TableOrderFilter';
import './OrderPage.scss';
import {
  getItemNotes,
  getOrderManagement,
  resendOrdersToPrinter,
} from '../../api';
import {
  ELoadingStates,
  ManagementOrdersRequest,
  OrdersResponse,
  StatusOptions,
} from '@gfxco/contracts';
import SectionHeader from '../../components/SectionHeader';
import ButtonBadgeTab from '../../components/ButtonBadgeTab';
import Icon from '../../components/Icons/Icon';
import {GFXToastLaunch} from '../../components/ToastMessage/ToastMessage';

const TABLE_HEADERS = [
  'Image',
  'Items',
  'Ecommerce ID',
  'Date',
  'Customer',
  'GFX ID',
  'Status',
  'Printer',
  'Price',
  'Actions',
];

interface IStatusOption {
  status: StatusOptions;
  text: string;
}

const STATUSES_OPTIONS: IStatusOption[] = [
  {status: '', text: 'All orders'},
  {status: 'pending-designs', text: 'Pending designs'},
  {status: 'rendering-art', text: 'Rendering art'},
  {status: 'printing', text: 'Printing'},
  {status: 'error', text: 'Error'},
  {status: 'shipped', text: 'Shipped'},
  {status: 'arts-sent', text: 'Art sent'},
  {status: 'rejected', text: 'Cancelled'},
];

export const OrderPage: React.FC = () => {
  const shopSelected = useAppSelector(selectShop);
  const shopId = shopSelected?.id;
  const [orders, setOrders] = React.useState<OrdersResponse[]>([]);
  const [fetchStatus, setFetchStatus] = React.useState<ELoadingStates>(
    ELoadingStates.IDLE,
  );
  const [offset, setOffset] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [limit] = React.useState(50);
  const [status, setStatus] = React.useState<StatusOptions>('');
  const [time, setTime] = React.useState('1');
  const [searchInput, setSearchInput] = React.useState('');
  const [totalOrders, setTotalOrders] = React.useState(0);
  const [totalOrdersByStatus, setTotalOrdersByStatus] = React.useState<
    [
      {
        status: string;
        count: string;
      },
    ]
  >();

  let totalOrdersStatusSelected = totalOrders;
  if (totalOrdersByStatus && status !== '') {
    const count = totalOrdersByStatus.find((to) => to.status === status)?.count;

    totalOrdersStatusSelected = count ? parseInt(count, 10) : totalOrders;
  }

  const [refreshOrders, setRefreshOrders] = React.useState(false);

  const isLoading = fetchStatus === ELoadingStates.LOADING;

  React.useEffect(() => {
    if (!isLoading) {
      cleanState();
      getOrders();
    }
  }, [offset, limit, status, time, shopId, searchInput]);

  React.useEffect(() => {
    if (refreshOrders) {
      cleanState();
      getOrders();
      setRefreshOrders(false);
    }
  }, [refreshOrders]);

  React.useEffect(() => {
    if (shopSelected) {
      cleanState();
      getOrders();
    }
  }, [shopSelected]);

  const getOrders = async () => {
    try {
      const params: ManagementOrdersRequest = {offset, limit, shopId};
      if (status.length) params.status = status;
      if (time.length) params.time = time;
      if (searchInput.length) params.searchBy = searchInput;

      const orders = await getOrderManagement(params);
      if (orders) {
        setOrders(orders.results);
        setTotalOrders(orders.total);
        setTotalOrdersByStatus(orders.counts);
      }
      setFetchStatus(ELoadingStates.LOADED);
    } catch (error) {
      console.error(error);
      setFetchStatus(ELoadingStates.FAILED);
    }
  };

  const cleanState = () => {
    setFetchStatus(ELoadingStates.LOADING);
    setOrders([]);
  };

  const cleanPagination = () => {
    setOffset(0);
    setCurrentPage(1);
  };

  const handlePagination = (action: string, page?: number) => {
    if (action === 'first') {
      setOffset(0);
      setCurrentPage(1);
    } else if (action === 'prev') {
      setOffset(offset - limit);
      setCurrentPage(currentPage - 1);
    } else if (action === 'next') {
      setOffset(offset + limit);
      setCurrentPage(currentPage + 1);
    } else if (
      (action === 'last' || action === 'page') &&
      typeof page === 'number'
    ) {
      setOffset(limit * page);
      setCurrentPage(page + 1);
    }
  };

  const handleStatus = (status: StatusOptions) => {
    cleanPagination();
    setStatus(status);
  };

  const handleTimeSelected = (time: string) => {
    setTime(time);
  };

  const handleSearchByChanged = (value: string) => {
    setSearchInput(value);
  };

  if (fetchStatus === ELoadingStates.FAILED) {
    return <div>Error loading orders!.</div>;
  }

  const handleResendToPrinter = (id: number, itemId?: number) => {
    resendOrdersToPrinter({id, itemId})
      .then((e) => {
        GFXToastLaunch(
          'Order has been resent to the printer successfully',
          5000,
          {
            showAt: 'bottom',
            showIcon: true,
            alertType: 'success',
          },
        );
      })
      .catch((e) => {
        GFXToastLaunch('Something went wrong resending to the printer', 5000, {
          showAt: 'bottom',
          showIcon: true,
          alertType: 'danger',
        });
      });
  };

  const handleLoadItemNotes = async (orderId: number) => {
    const response = await getItemNotes(orderId);
    return response ?? [];
  };

  return (
    <div id="OrderPage">
      <SectionHeader
        title="Management"
        subtitle="Overview of your production queue."
        rightContent={
          <TableOrderFilter
            handleStatus={handleStatus}
            handleTime={handleTimeSelected}
            handleSearchBar={handleSearchByChanged}
            handleRefreshOrder={() => setRefreshOrders(true)}
            isLoading={isLoading}
          />
        }
      />

      <div className="management-statuses">
        {STATUSES_OPTIONS.map((item) => {
          let countValue = item.status === '' ? totalOrders : 0;

          if (totalOrdersByStatus) {
            const value = totalOrdersByStatus.find(
              (status) => status.status === item.status,
            )?.count;

            if (value) countValue = parseInt(value);
          }

          return (
            <ButtonBadgeTab
              key={item.status}
              buttonText={item.text}
              active={item.status === status}
              onClick={() => {
                handleStatus(item.status);
              }}
              countValue={countValue}
              fetchStatus={fetchStatus}
              badgeClass={item.status}
            />
          );
        })}
      </div>

      {!orders.length && isLoading && <Loading text="Loading orders..." />}

      {orders.length === 0 && !isLoading && (
        <div className="empty-orders">
          <Icon name="cartWithAlert"></Icon>
          <p>{"Looks like you haven't received any orders yet"}</p>
        </div>
      )}

      {orders.length > 0 && !isLoading && (
        <div className="orders-table-container">
          <Table responsive hover className="orders-table">
            <thead>
              <tr className="table-titles-row">
                <th className="table-titles"></th>
                {TABLE_HEADERS.map((value, index) => (
                  <th className="table-titles" key={`head-${index}`}>
                    {value}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <OrderRow
                  key={order.id}
                  order={order}
                  shopDomain={
                    shopSelected?.shopify_domain ??
                    shopSelected?.public_shopify_domain
                  }
                  onResendToPrinter={handleResendToPrinter}
                  onLoadItemsNotes={handleLoadItemNotes}
                />
              ))}
            </tbody>
          </Table>
          <CustomPagination
            limit={limit}
            currentPage={currentPage}
            total={totalOrdersStatusSelected}
            handlePagination={handlePagination}
          />
        </div>
      )}
    </div>
  );
};

export default OrderPage;
