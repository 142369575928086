import React from 'react';
import {useNavigate} from 'react-router-dom';
import usePagination from '../../../hooks/usePagination';
import useQueryParams from '../../../hooks/useQueryParams';
import {ReactComponent as ImageIcon} from '../../../assets/icons/image-fill-icon.svg';
import {ReactComponent as EditIcon} from '../../../assets/icons/edit-icon.svg';
import {ReactComponent as FolderIcon} from '../../../assets/icons/folder-icon.svg';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  getImagesAsync,
  resetImagesPages,
  getImagesByPage,
  selectTotalImages,
  fetchStatusByPage,
} from '../../../features/images/loadImages';
import {
  getSubCollectionsAsync,
  getSubcollectionsFetchStatus,
  getEntityById,
  resetSubCollectionsFetchStatus,
} from '../../../features/collections/loadCollections';
import PreviewSection from '../../../components/ImageDashboard/PreviewSection';
import CollectionBreadcrumb from '../../../components/ImageDashboard/CollectionBreadcrumb';
import CustomPagination from '../../../components/CustomPagination';
import GFXImage from '../../../components/Image/Image';
import CollectionsWizard from '../../../components/ImageDashboard/CollectionsWizard';
import ImageBanner from '../../../components/ImageBanner';
import {
  ELoadingStates,
  IImageRequest,
  ICollectionResponse,
} from '@gfxco/contracts';

import './ViewCollection.scss';

import {ChevronDown, ChevronUp, XLg} from 'react-bootstrap-icons';

interface RequiredProps {
  name: string;
  handleOnDeleteCollection: (id: number) => void;
  handleOnDeleteImage: (id: number) => void;
}

interface OptionalProps {
  id?: number;
  shopId?: number;
}

type ViewCollectionProps = RequiredProps & OptionalProps;

const ViewCollection: React.FC<ViewCollectionProps> = ({
  name,
  shopId,
  id,
  handleOnDeleteCollection,
  handleOnDeleteImage,
}) => {
  const [queryParams] = useQueryParams();
  const sortBy = queryParams.sortBy || 'newest';
  const search = queryParams.search || '';
  const preview = queryParams.preview || 'gallery';
  const [{offset, currentPage, limit}, handlePagination] = usePagination();
  const [showCollectionModal, setShowCollectionModal] = React.useState(false);
  const [showBanner, setShowBanner] = React.useState(true);
  const [collectionEditing, setCollectionEditing] =
    React.useState<ICollectionResponse>();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(true);

  const images = useAppSelector((state) => getImagesByPage(state, currentPage));
  const totalImages = useAppSelector((state) => selectTotalImages(state));
  const imagesStatus = useAppSelector((state) =>
    fetchStatusByPage(state, currentPage),
  );

  const subCollectionsStatus = useAppSelector((state) =>
    getSubcollectionsFetchStatus(state),
  );

  const collection = useAppSelector((state) => getEntityById(state, +id!));

  React.useEffect(() => {
    if (imagesStatus === ELoadingStates.IDLE && id) {
      const params: IImageRequest & {page: number} = {
        shopId,
        offset,
        limit,
        page: currentPage,
        collections: [+id],
        order: sortBy === 'newest' ? 'desc' : 'asc',
      };
      if (search) params.name = search;
      dispatch(getImagesAsync(params));
    }
  }, [shopId, offset, imagesStatus, limit, id]);

  React.useEffect(() => {
    if (subCollectionsStatus === ELoadingStates.IDLE && id && shopId) {
      const params: {shopId: number; collectionId: number} = {
        shopId,
        collectionId: +id,
      };
      dispatch(getSubCollectionsAsync(params));
    }
  }, [shopId, id, subCollectionsStatus, collection]);

  React.useEffect(() => {
    if (imagesStatus === ELoadingStates.IDLE && id) {
      const params: IImageRequest & {page: number} = {
        shopId,
        offset,
        limit,
        page: currentPage,
        collections: [+id],
        order: sortBy === 'newest' ? 'desc' : 'asc',
      };
      if (search) params.name = search;
      dispatch(getImagesAsync(params));
    }
  }, [shopId, offset, imagesStatus, limit, id]);

  React.useEffect(() => {
    handlePagination('reset');
    dispatch(resetImagesPages());
    dispatch(resetSubCollectionsFetchStatus());
  }, [queryParams.sortBy, queryParams.search, id, shopId]);

  const handleOnEdit = (collection: ICollectionResponse) => {
    setCollectionEditing(collection);
    setShowCollectionModal(true);
  };

  const handleOnReset = () => {
    dispatch(resetSubCollectionsFetchStatus());
    setCollectionEditing(undefined);
  };

  const handleOnCloseCollectionModal = () => {
    handleOnReset();
    setShowCollectionModal(false);
  };

  if (imagesStatus === ELoadingStates.FAILED) {
    return <div>Error loading images!.</div>;
  }

  return (
    <div id="ViewCollection">
      <div id="ViewCollection-Header">
        <CollectionBreadcrumb
          items={[
            {
              name: 'All collections',
              navigation: '/designs/collections',
              icon: <FolderIcon />,
            },
            {
              name,
              navigation: `/designs/collections/${encodeURIComponent(
                name,
              )}?collectionId=${id}`,
            },
          ]}
        />
      </div>
      <ImageBanner show={showBanner} onClose={() => setShowBanner(false)} />
      {subCollectionsStatus === ELoadingStates.LOADED &&
        collection &&
        !!collection.subCollections?.length && (
          <div className="subcollection-box">
            <div className="collapse-box">
              <a className="link" onClick={() => setOpen(!open)}>
                {!open ? (
                  <ChevronDown className="icon" />
                ) : (
                  <ChevronUp className="icon" />
                )}
                <span>Sub-collections</span>
              </a>
            </div>
            {open && (
              <Container fluid>
                <Row className="subcollection-box-content">
                  {collection.subCollections?.map((subcollection) => (
                    <Col
                      key={subcollection.id}
                      xs={8}
                      md="auto"
                      className={`${
                        preview === 'list' ? 'list-items-box-preview' : ''
                      }`}
                    >
                      <div
                        className={`item-box ${
                          preview === 'list' ? 'list-item-box' : ''
                        }`}
                      >
                        <div className="item-counter">
                          <ImageIcon />
                          <span>{subcollection.imagesCount}</span>
                        </div>
                        <div className="Preview__ActionButtons">
                          <button
                            className="button-actions button-tooltip"
                            onClick={() =>
                              handleOnEdit({
                                id: subcollection.id,
                                name: subcollection.name,
                                description: subcollection.description,
                                imageUrl: subcollection.imageUrl!,
                                largeImageUrl: subcollection.largeImageUrl!,
                              })
                            }
                            data-tooltip="Edit subcollection"
                          >
                            <EditIcon />
                          </button>
                          <button
                            className="icon-fill button-tooltip"
                            data-tooltip="Delete"
                            onClick={() =>
                              handleOnDeleteCollection(subcollection.id)
                            }
                          >
                            <XLg />
                          </button>
                        </div>
                        <div
                          onClick={() =>
                            navigate(
                              `${encodeURIComponent(
                                subcollection.name,
                              )}?collectionId=${id}&subId=${subcollection.id}`,
                            )
                          }
                        >
                          <div className="gallery">
                            <div className="box first">
                              <GFXImage
                                src={subcollection.imageUrl}
                                placeholderSrc="/loading-render.gif"
                              />
                            </div>
                            <div className="box second"></div>
                            <div className="box third"></div>
                          </div>
                          <div className="box-name">
                            <span>{subcollection.name}</span>
                            <span className="box-subtitle">
                              {subcollection.description}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Container>
            )}
          </div>
        )}
      <PreviewSection
        onDeleteCollection={handleOnDeleteCollection}
        onDeleteImage={handleOnDeleteImage}
        type="images"
        list={images}
        handleOnClick={(name: string) => {}}
        isLoading={imagesStatus !== ELoadingStates.LOADED}
        listView={preview === 'list'}
      />
      <CollectionsWizard
        open={showCollectionModal}
        onClose={handleOnCloseCollectionModal}
        onDeleteImage={handleOnDeleteImage}
        collectionDraft={collectionEditing}
        parentId={+id!}
      />
      <CustomPagination
        limit={limit}
        currentPage={currentPage}
        total={totalImages}
        handlePagination={handlePagination}
        entity="Images"
      />
    </div>
  );
};

export default ViewCollection;
