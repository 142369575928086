import React, {SyntheticEvent} from 'react';
import {ReactSVG as SVG} from 'react-svg';
import './icon.scss';

const IconMap = {
  undoAction: 'undo_action.svg',
  redoAction: 'redo_action.svg',
  backFullScreen: 'back-full-screen.svg',
  centerHorizontally: 'center-horizontally.svg',
  centerVertically: 'center-vertically.svg',
  rotateLeft: 'rotate-left.svg',
  rotateRight: 'rotate-right.svg',
  rotateRestore: 'rotate-restore.svg',
  zoomIn: 'zoom-in.svg',
  zoomOut: 'zoom-out.svg',
  fullScreen: 'full-screen.svg',
  garmentColor: 'garment-color.svg',
  backgroundSlotIcon: 'background-slot-icon.svg',
  backgroundIcon: 'background-icon.svg',
  magnifierIcon: 'magnifier-icon.svg',
  chooseProductType: 'choose-product-type-icon.svg',
  createCustomCanva: 'create-custom-canva-icon.svg',
  createYourDesignTemplate: 'create-your-design-template.svg',
  upArrow: 'arrow-up-icon.svg',
  leftIcon: 'left-icon.svg',
  rightIcon: 'right-icon.svg',
  textSlotIcon: 'text-slot-icon.svg',
  downArrow: 'down-arrow-icon.svg',
  downArrowWhite: 'arrow-down-white-variant-icon.svg',
  profileImage: 'profile-image-icon.svg',
  addImagePlus: 'image-plus-icon.svg',
  camera: 'camera-icon.svg',
  pencil: 'pencil-icon.svg',
  lock: 'lock-icon.svg',
  longDownArrowIcon: 'long-arrow-down-icon.svg',
  longUpArrowIcon: 'long-arrow-up-icon.svg',
  checkIcon: 'check-icon.svg',
  checkIconBlue: 'check-fill-blue-icon.svg',
  infoIcon: 'info-icon.svg',
  info: 'info-grey-icon.svg',
  xIcon: 'x-icon.svg',
  xIconCircle: 'x-circle-icon.svg',
  shipping: 'shipping-icon.svg',
  comment: 'comment-icon.svg',
  settingsIcon: 'setting-icon.svg',
  productIcon: 'product-icon.svg',
  myStoryIcon: 'my-store-icon.svg',
  eyeIcon: 'eye-icon.svg',
  eyeClosedIcon: 'eye-closed-icon.svg',
  cartWithAlert: 'cart-with-alert.svg',
  shopifyIcon: 'shopify-icon.svg',
  flipIcon: 'flip-icon.svg',
  publishIcon: 'publish-icon.svg',
  home: 'home-icon.svg',
  homeFill: 'home-fill-icon.svg',
  homeFillWhite: 'home-fill-white-variant-icon.svg',
  dashboard: 'dashboard-icon.svg',
  product: 'product-icon.svg',
  image: 'image-menu-icon.svg',
  font: 'font-icon.svg',
  label: 'label-icon.svg',
  order: 'order-icon.svg',
  management: 'management-icon.svg',
  moderation: 'moderation-icon.svg',
  samplePortal: 'sample-portal-icon.svg',
  editor: 'editor-icon.svg',
  history: 'history-icon.svg',
  settings: 'setting-icon.svg',
  subscription: 'subscription-icon.svg',
  billing: 'billing-icon.svg',
  user: 'user-menu-icon.svg',
  help: 'help-icon.svg',
  account: 'account-icon.svg',
  signOut: 'sign-out-icon.svg',
  emailConfirmation: 'email-confirmation-icon.svg',
  greeting: 'greeting-icon.svg',
  congratulations: 'congratulations-icon.svg',
  emptyFolder: 'empty-folder-icon.svg',
  folder: 'folder-open-icon.svg',
  duplicate: 'icon_duplicate.svg',
  message: 'message-icon.svg',
  videoPlayer: 'video-player-icon.svg',
  imageNote: 'image-note-icon.svg',
  save: 'save-icon.svg',
  edit: 'edit-icon.svg',
  plusFolder: 'folder-plus-icon.svg',
  stickerPlus: 'sticker-plus-icon.svg',
  arrowDownRight: 'arrow-down-right.svg',
  imageFill: 'image-fill-icon.svg',
  notFound: 'not-found.svg',
  hasLabel: 'has-label-icon.svg',
  notHasLabel: 'not-has-label-icon.svg',
  reload: 'reload-icon.svg',
  separator: 'separator-icon.svg',
  createNewStore: 'create-new-store-icon.svg',
  alignLeft: 'align-left-icon.svg',
  alignCenter: 'align-center-icon.svg',
  alignRight: 'align-right-icon.svg',
  templateConfiguration: 'template-configuration-icon.svg',
  delete: 'delete-icon.svg',
  duplicate2: 'duplicate-icon.svg',
  editDesign: 'edit-design-icon.svg',
  defaultStoreImage: 'store-default-image.svg',
  exclamationCircle: 'exclamation-circle-icon.svg',
  information: 'information-icon.svg',
  printfulLogo: 'printful-logo.svg',
  fifthSunLogo: 'fifth-sun-logo.svg',
  fifthSunLogo2: 'fifth-sun-logo_2.svg',
  notConnected: 'not-connect-icon.svg',
  sendArts: 'send-arts-icon.svg',
  externalLink: 'external-link-icon.svg',
  renderArt: 'render-art.svg',
  arrowRepet: 'arrow-repet-icon.svg',
  imageOutline: 'image-outline-icon.svg',
};

interface RequiredProps {
  name: keyof typeof IconMap;
}

interface OptionalProps {
  classes: string;
  action: (e: SyntheticEvent) => void;
  box: boolean;
  fillColor: {default: string; hover?: string; disabled?: string};
  isHovering?: boolean;
  tooltip: string;
  disabled: boolean;
  width: number;
  height: number;
  onClick: (e: SyntheticEvent) => void;
}

type Props = RequiredProps & Partial<OptionalProps>;

const getFillColor = (props: Props) => {
  let fillColor = props.fillColor?.default;

  if (props.isHovering) {
    fillColor = props.fillColor?.hover;
  }

  if (props.disabled) {
    fillColor = props.fillColor?.disabled || '#ccc';
  }

  return fillColor;
};

const Icon: React.FC<Props> = (props) => {
  const tooltip = props.tooltip;
  const getSvgElements = (svg: any) => {
    return [
      ...svg.getElementsByTagName('path'),
      ...svg.getElementsByTagName('rect'),
    ].filter((el) => el);
  };

  const [icon, setIcon] = React.useState<null | string>();

  React.useEffect(() => {
    const fileName = IconMap[props.name];
    const iconName = fileName.replace('.svg', '');

    if (!icon || !icon.includes(iconName)) {
      import(`../../assets/icons/${fileName}`)
        .then((module) => {
          setIcon(module.default);
        })
        .catch((error) => {
          console.error(`Icon ${props.name} not found`, error);
        });
    }
  }, [props.name]);

  const IconTooltip = () => {
    return (
      <div className="icon_tooltip">
        <p>{tooltip}</p>
      </div>
    );
  };

  if (!icon) {
    return null;
  }

  if (!props.action) {
    return (
      <>
        <SVG
          className={`icon_element icon_without_action ${props.classes || ''}`}
          src={icon!}
          beforeInjection={(svg) => {
            const fillColor = getFillColor(props);

            if (fillColor) {
              const elements = getSvgElements(svg);
              elements.forEach((element) => {
                if (!element.classList.contains('skip-svg-fill')) {
                  element.setAttribute('fill', fillColor);
                }
              });
            }

            if (props.width) {
              svg.setAttribute('width', `${props.width}`);
            }

            if (props.height) {
              svg.setAttribute('height', `${props.height}`);
            }
          }}
          onClick={props.onClick || (() => {})}
        />
        {tooltip && <IconTooltip />}
      </>
    );
  }

  return (
    <button
      onDoubleClick={(e) => e.preventDefault()}
      onClick={props.action}
      disabled={props.disabled}
      className={`icon_button ${props.classes || ''} ${
        props.box ? 'square_icon_background' : ''
      }`}
    >
      {tooltip && <IconTooltip />}
      <SVG
        className="icon_element"
        src={icon!}
        beforeInjection={(svg) => {
          const fillColor = getFillColor(props);

          if (fillColor) {
            const elements = getSvgElements(svg);
            elements.forEach((element) => {
              if (!element.classList.contains('skip-svg-fill')) {
                if (element.hasAttribute('stroke')) {
                  element.setAttribute('stroke', fillColor);
                } else {
                  element.setAttribute('fill', fillColor);
                }
              }
            });
          }
        }}
      />
    </button>
  );
};

export default Icon;
