import React from 'react';
import Table from 'react-bootstrap/Table';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button';
import {EModerationItemStatus, IItemNotes, IOrderItem} from '@gfxco/contracts';
import Loading from '../../../components/Loading';
import {getDesignStatus} from '../../../libs/getStatus';
import Icon from '../../../components/Icons/Icon';

import './ItemsRow.scss';

const TABLE_HEADERS = [
  'Proof',
  'Art files',
  'Quantity',
  'Printer',
  'Status',
  'Notes',
  'Advanced Actions',
];

export interface RequiredProps {
  items: IOrderItem[];
  onResendToPrinter: (itemId: number) => void;
  disableResend: boolean;
  orderNotes: IItemNotes[];
}
export interface OptionalProps {
  loadingNotes?: boolean;
  children?: React.ReactNode;
}

type ItemsRowProps = RequiredProps & OptionalProps;

export const ItemsRow: React.FC<ItemsRowProps> = ({
  items,
  onResendToPrinter,
  disableResend,
  orderNotes,
  loadingNotes,
}) => {
  const getOrderByItemId = (itemId: number) => {
    const itemNote = orderNotes.find((note) => note.itemId === itemId);
    return itemNote?.notes ?? [];
  };

  return (
    <Table responsive className="order-detail-table">
      <thead>
        <tr>
          {TABLE_HEADERS.map((value, index) => (
            <th key={`head-${index}`}>{value}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {items.map((item) => {
          const itemNotes = getOrderByItemId(item.id);
          const showNotes = itemNotes.length > 0;
          const itemStatus = getDesignStatus({
            designStatus: item.designStatus,
            date_shipped: item.date_shipped,
            processingStart: item.processingStart,
            printer: item.printer,
            moderationStatus: item.moderation_status,
          });

          return (
            <tr key={item.id} className="ItemRow">
              <td className="proofs-cell">
                <div className="ItemRow__ProofFiles">
                  <div>
                    <img src={item.proofFront} />
                  </div>
                  <div>
                    <img src={item.proofBack} />
                  </div>
                </div>
              </td>
              <td>
                <div className="arts-button-container">
                  <a
                    href={item.artFront}
                    target="_blank"
                    rel="noreferrer"
                    role="button"
                    aria-pressed="true"
                  >
                    <Button className="art-button" disabled={!item.artFront}>
                      <Icon name="imageOutline" /> Front Art
                    </Button>
                  </a>
                  <a
                    href={item.artBack}
                    target="_blank"
                    rel="noreferrer"
                    role="button"
                    aria-pressed="true"
                  >
                    <Button className="art-button" disabled={!item.artBack}>
                      <Icon name="imageOutline" /> Back Art
                    </Button>
                  </a>
                </div>
              </td>
              <td>{item.quantity} item(s)</td>
              <td>{item.printer ? item.printer : 'Not Printed'}</td>
              <td className={itemStatus.toLowerCase()}>{itemStatus}</td>
              <td>
                {loadingNotes ? (
                  <Loading text="Loading notes..." spinnerOnly />
                ) : showNotes ? (
                  <OverlayTrigger
                    key={'top'}
                    placement={'top'}
                    overlay={
                      <Popover body>
                        <div className="notes-popover">
                          {itemNotes.map((note, index) => (
                            <span key={index}>{note}</span>
                          ))}
                        </div>
                      </Popover>
                    }
                  >
                    <a rel="noreferrer" aria-pressed="true">
                      See Notes
                    </a>
                  </OverlayTrigger>
                ) : (
                  'No notes'
                )}
              </td>
              <td>
                <button
                  disabled={
                    disableResend ||
                    item.moderation_status !== EModerationItemStatus.ACCEPTED
                  }
                  className="resend-order-button"
                  onClick={() => onResendToPrinter(item.id)}
                >
                  <Icon name="arrowRepet" />
                  Resend Art
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
export default ItemsRow;
