import React from 'react';
import {DateTime} from 'luxon';
import Button from 'react-bootstrap/Button';
import {ChevronDown, ChevronUp} from 'react-bootstrap-icons';
import Badge from 'react-bootstrap/Badge';
import {IItemNotes, IOrder} from '@gfxco/contracts';
import ItemsRow from '../ItemsRow/ItemsRow';
import {GFXToastLaunch} from '../../../components/ToastMessage/ToastMessage';
import {getOrderStatus, disableResendToPrinter} from '../../../libs/getStatus';
import Icon from '../../../components/Icons/Icon';

import './OrderRow.scss';

export interface RequiredProps {
  order: IOrder;
  onResendToPrinter: (id: number, itemId?: number) => void;
  onLoadItemsNotes: (orderId: number) => Promise<IItemNotes[]>;
}
export interface OptionalProps {
  shopDomain?: string;
  children?: React.ReactNode;
}

type OrderRowProps = RequiredProps & OptionalProps;

export const OrderRow: React.FC<OrderRowProps> = ({
  order,
  shopDomain,
  onResendToPrinter,
  onLoadItemsNotes,
}) => {
  const [open, setOpen] = React.useState(false);
  const [orderNotes, setOrderNotes] = React.useState<IItemNotes[]>([]);
  const [loadingNotes, setLoadingNotes] = React.useState(false);
  const shopUrl = `https://${shopDomain}/admin/orders/${order.shopifyId}`;
  const orderStatus = getOrderStatus(order.status);
  const disableResendButton = disableResendToPrinter(order.status);

  const handleRowDetailsOpen = () => {
    setOpen(!open);
  };

  const imagePreview =
    (order.items && order.items.length > 0 && order.items[0].proofFront) || '';

  React.useEffect(() => {
    if (open) {
      setLoadingNotes(true);
      onLoadItemsNotes(order.id)
        .then((notes) => {
          setOrderNotes(notes);
        })
        .catch(() => {
          GFXToastLaunch('Error loading notes', 5000, {
            showAt: 'top',
            right: '3.5rem',
            top: '3.5rem',
            showIcon: true,
            alertType: 'danger',
          });
        })
        .finally(() => {
          setLoadingNotes(false);
        });
    }
  }, [open]);

  return (
    <>
      <tr
        key={`row-${order.id}`}
        data-toggle="collapse"
        data-target=".multi-collapse1"
        aria-controls="multiCollapseExample1"
        className="OrderRow"
      >
        <td
          className="expand-button-cell"
          onClick={() => handleRowDetailsOpen()}
        >
          <Button className="expand-button">
            {open ? <ChevronUp /> : <ChevronDown />}
          </Button>
        </td>
        <td className="image-cell">
          <div className="preview-image-container">
            <img src={imagePreview} alt="product" className="product-image" />
          </div>
        </td>
        <td className="total-items">{order.totalItems}</td>
        <td>
          {shopDomain ? (
            <a href={shopUrl} target="_blank" rel="noreferrer">
              {order.shopifyOrderId}
            </a>
          ) : (
            order.shopifyOrderId
          )}
        </td>
        <td>{DateTime.fromISO(order.created_at).toFormat('dd-MMM-yyyy')}</td>
        <td>{order.customer}</td>
        <td>{order.id}</td>
        <td>
          <Badge
            className={`badge-${orderStatus.name}`}
            bg={orderStatus.color || 'secondary'}
          >
            {orderStatus.description}
          </Badge>
        </td>
        <td>
          {order.printers.length
            ? order.printers.map((printer) => (
                <>
                  {printer}
                  {order.printers.length > 1 && ','}
                </>
              ))
            : 'Not printed'}
        </td>
        <td>$ {order.finalPrice}</td>
        <td>
          <button
            disabled={disableResendButton}
            className="resend-order-button"
            onClick={() => onResendToPrinter(order.id)}
          >
            <Icon name="arrowRepet" />
            Resend Order
          </button>
        </td>
      </tr>
      {open && (
        <tr className="OrderRow open-row">
          <td colSpan={12} className="items_table">
            <ItemsRow
              items={order.items}
              onResendToPrinter={(itemId: number) =>
                onResendToPrinter(order.id, itemId)
              }
              orderNotes={orderNotes}
              loadingNotes={loadingNotes}
              disableResend={disableResendButton}
            />
          </td>
        </tr>
      )}
    </>
  );
};

export default OrderRow;
