import React from 'react';
import {Routes, Route} from 'react-router-dom';
import ReactGA from 'react-ga4';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import SelectedShopRoute from './SelectedShopRoute';
import SubscriptionRoute from './SubscriptionRoute';
import {ModerationByShopPage} from './pages/ModerationByShopPage';
import OrderPage from './pages/OrderPage';
import {EditDesignLink} from './pages/EditDesignLink';
import SignInSide from './pages/SignInPage';
import SignUpSide from './pages/SignUpPage';
import {SamplePortalPage} from './pages/SamplePortalPage';
import {SamplePortalLogPage} from './pages/SamplePortalLogPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage/ForgotPasswordPage';
import CookiePolicyPage from './pages/CookiePolicyPage/CookiePolicyPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage/PrivacyPolicyPage';
import DesignsPage from './pages/DesignsPage';
import ModalSamplePage from './pages/ModalSamplePage';
import FontsPage from './pages/FontsPage';
import {GFXInstanceProvider as GFXProvider} from '@best-apps/gfx-editor';
import SlotTemplatePage from './pages/SlotTemplatePage/SlotTemplatePage';
import DesignWizard from './pages/DesignWizard';
import MyStorePage from './pages/MyStorePage';
import LogoutPage from './pages/LogoutPage';
import AccountPage from './pages/AccountPage';
import LabelsPage from './pages/LabelsPage';
import UserManagementPage from './pages/UserManagementPage';
import SyncPage from './pages/SyncPage';
import ImagesDashboardRoot from './components/ImageDashboard/ImagesDashboardRoot';
import HomePage from './pages/HomePage';
import PublicHomePage from './pages/PublicHomePage';
import ConfirmationUserPage from './pages/ConfirmationUserPage';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import DashboardPage from './pages/DashboardPage/DashboardPage';
import PrintersPage from './pages/PrintersPage';

const {REACT_APP_GA_TRACKING_ID: GATrackingID} = process.env;

if (GATrackingID) {
  ReactGA.initialize(GATrackingID);
}

export const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route element={<PublicRoute />}>
        {/* PUBLIC ROUTES */}
        <Route path="modal-sample" element={<ModalSamplePage />} />
        <Route path="logout" element={<LogoutPage />} />
        <Route element={<PublicHomePage />}>
          <Route path="login" element={<SignInSide />} />
          <Route path="signup" element={<SignUpSide />} />
          <Route path="signup/confirm" element={<ConfirmationUserPage />} />
        </Route>
        <Route path="forgot-password" element={<ForgotPasswordPage />} />
        <Route path="cookies" element={<CookiePolicyPage />} />
        <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="public" element={<div>Public</div>} />
        <Route
          path="/public/edit-design/:token"
          element={
            <GFXProvider>
              <EditDesignLink />
            </GFXProvider>
          }
        />
      </Route>
      {/* PRIVATE ROUTES */}
      <Route element={<PrivateRoute />}>
        <Route element={<SelectedShopRoute />}>
          <Route path="connect">
            <Route index element={<SyncPage />}></Route>
          </Route>
          <Route element={<DashboardPage />}>
            <Route path="/" element={<HomePage />} />
            <Route path="user-management" element={<UserManagementPage />} />
            <Route path="account">
              <Route index element={<AccountPage />}></Route>
            </Route>
            <Route element={<SubscriptionRoute />}>
              <Route path="designs" element={<DesignsPage />}>
                <Route
                  path="create"
                  element={
                    <GFXProvider>
                      <DesignWizard />
                    </GFXProvider>
                  }
                />
                <Route
                  path="edit/draft-canvas/:draftCanvasId"
                  element={
                    <GFXProvider>
                      <DesignWizard />
                    </GFXProvider>
                  }
                />
                <Route
                  path="edit/:templateId"
                  element={
                    <GFXProvider>
                      <DesignWizard />
                    </GFXProvider>
                  }
                />
                <Route path="fonts" element={<FontsPage />}></Route>
                <Route path="controls">
                  <Route path=":id" element={<SlotTemplatePage />} />
                </Route>
                <Route path="*" index element={<ImagesDashboardRoot />} />
              </Route>
              <Route path="products">
                <Route path="labels" element={<LabelsPage />} />
              </Route>
              <Route path="my-store" element={<MyStorePage />} />
              <Route path="printers" element={<PrintersPage />} />
              <Route path="orders">
                <Route index element={<OrderPage />}></Route>
                <Route
                  path="moderation"
                  element={<ModerationByShopPage />}
                ></Route>
              </Route>
            </Route>
            <Route path="sample-portal">
              <Route
                index
                element={
                  <GFXProvider>
                    <SamplePortalPage />
                  </GFXProvider>
                }
              />
              <Route path="log" element={<SamplePortalLogPage />} />
            </Route>
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<ErrorPage title="Page Not Found" />} />
    </Routes>
  );
};
