import React from 'react';
import {useForm} from 'react-hook-form';
import classNames from 'classnames';
import {IWebhookIntegrationFormValues} from '@gfxco/contracts';
import {Form, OverlayTrigger, Tooltip} from 'react-bootstrap';

import PrinterCollapsedContent from '../PrinterCollapseContent';
import Icon from '../../../components/Icons/Icon';
import Button from '../../../components/Button';

type WebhookFormProps = {
  id: string;
  webhookUrl?: string;
  loading?: boolean;
  connected?: boolean;
  image?: string | React.ReactNode;
  learnHowUrl?: string;
  disabled?: boolean;
  onSubmitted?: (params: IWebhookIntegrationFormValues) => void;
};

const WebhookForm: React.FC<WebhookFormProps> = ({
  id,
  webhookUrl,
  loading = false,
  connected = false,
  image,
  learnHowUrl,
  disabled = false,
  onSubmitted,
}) => {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<IWebhookIntegrationFormValues>({});

  const handleFormSubmit = (params: IWebhookIntegrationFormValues) => {
    if (onSubmitted) {
      onSubmitted(params);
    }
  };

  return (
    <PrinterCollapsedContent
      id={id}
      loading={loading}
      connected={connected}
      image={image}
      errored={!!errors.webhookUrl}
    >
      <form
        className="WebhookForm"
        onSubmit={!loading ? handleSubmit(handleFormSubmit) : undefined}
      >
        <Form.Group controlId="formWebhookURL">
          <Form.Label>Webhook URL*</Form.Label>
          <OverlayTrigger
            placement="right"
            delay={{show: 200, hide: 400}}
            overlay={
              <Tooltip id={`${id}-webhookUrlTooltip`}>
                Add the URL where you want to receive the arts files. See our
                documentation to learn more
              </Tooltip>
            }
          >
            <span className="d-inline-block tooltip-icon">
              <Icon name="information" />
            </span>
          </OverlayTrigger>
          {errors.webhookUrl?.type === 'required' && (
            <span className="form-error">Information required to continue</span>
          )}
          {errors.webhookUrl?.type === 'pattern' && (
            <span className="form-error">URL must be https</span>
          )}
          <Form.Control
            type="url"
            placeholder="https://gfx.webhook/123456"
            readOnly={connected}
            disabled={connected || disabled}
            autoComplete="off"
            defaultValue={webhookUrl}
            aria-invalid={errors.webhookUrl ? 'true' : 'false'}
            {...register('webhookUrl', {
              required: true,
              pattern: /^https:\/\/.*/,
            })}
          />
        </Form.Group>
        <div
          className={classNames({
            'connect-container': true,
            'connect-container--spaced': learnHowUrl,
          })}
        >
          {learnHowUrl && (
            <Button
              variant="link"
              href={learnHowUrl}
              target="_blank"
              rel="noreferrer"
            >
              <Icon name="externalLink" /> Learn how to set up this webhook
            </Button>
          )}
          {connected ? (
            <OverlayTrigger
              placement="top"
              delay={{show: 200, hide: 400}}
              overlay={
                <Tooltip id={`${id}-connect-button`}>
                  To connect a new webhook, contact support
                </Tooltip>
              }
            >
              <span className="d-inline-block">
                <Button variant="primary" type="submit" disabled>
                  <Icon height={18} width={18} name={'checkIcon'} />
                  Connected
                </Button>
              </span>
            </OverlayTrigger>
          ) : (
            <Button
              variant="primary"
              type="submit"
              disabled={loading || disabled}
            >
              <Icon height={18} width={18} name={'samplePortal'} />
              {loading ? 'Loading…' : 'Connect'}
            </Button>
          )}
        </div>
      </form>
    </PrinterCollapsedContent>
  );
};

export default WebhookForm;
