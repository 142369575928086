import React from 'react';

import './OrderCard.scss';
import {Badge} from 'react-bootstrap';
import {EModerationItemStatus} from '@gfxco/contracts';
import {formatDate} from '../../libs/formatUtils';
import GFXButton from '../Button';
import GFXImage from '../Image';
import GFXCheckbox from '../GFXCheckbox';
import {ReactComponent as EyeIcon} from '../../assets/icons/eye-icon.svg';
import Icon from '../Icons/Icon';
import classNames from 'classnames';
import {IModerationOrderExtended} from '../../features/moderation/loadModerationOrders';

interface RequiredProps {
  item: IModerationOrderExtended;
  viewDesignAction: (id: number) => void;
}

interface OptionalProps {
  selected?: boolean;
  onSelectChange?: (checked: boolean) => void;
}

type OrderCardProps = RequiredProps & OptionalProps;

export const OrderCard: React.FC<OrderCardProps> = (props) => {
  const status =
    props.item.moderationStatus === EModerationItemStatus.ON_HOLD
      ? {className: 'on-hold', text: 'On Hold'}
      : props.item.inReview
      ? {className: 'in-review', text: 'In Review'}
      : {className: 'new', text: 'New'};

  const autoApproval = props.item.autoApproval;
  const hasComments = props.item.commented;
  const autoApprovalClasses = classNames({
    'auto-approval-info': true,
    'auto-approval-info--off': autoApproval === false,
  });

  const orderCardClasses = classNames({
    'order-card': true,
    'order-card--selected': props.selected,
  });
  const handleOnSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onSelectChange) {
      props.onSelectChange(e.target.checked);
    }
  };

  return (
    <div className={orderCardClasses}>
      <div className="order-card-header">
        <div className="order-card-header-info">
          <div className="order-card-header-ecommerce">
            <GFXCheckbox
              id={`order-checkbox-${props.item.itemId}`}
              checked={props.item.selected}
              onChange={handleOnSelect}
              className="order-checkbox"
              label={props.item.orderName}
            />
          </div>
          <Badge className={`status-badge status-badge--${status.className}`}>
            {status.text}
          </Badge>
        </div>
      </div>
      <div className="order-card-proofs">
        {props.item.proofFront && (
          <GFXImage
            src={props.item.proofFront}
            alt=""
            placeholderSrc="/loading-render.gif"
          />
        )}
        {props.item.proofBack && (
          <GFXImage
            src={props.item.proofBack}
            alt=""
            placeholderSrc="/loading-render.gif"
          />
        )}
      </div>
      <div className="order-card-details">
        <div className="order-details-column-group">
          <div className="order-details-column-group__column order-details-column-group__column--1">
            <div
              className={classNames('shipping-info', {
                domestic: props.item.domestic && !props.item.external,
                international: !props.item.domestic && !props.item.external,
                external: props.item.external,
              })}
            >
              <Icon name={props.item.external ? 'renderArt' : 'shipping'} />
              <div className="shipping-info--text">
                <span>Shipping:</span>
                <b>
                  {props.item.external
                    ? 'Render art'
                    : props.item.domestic
                    ? 'Domestic'
                    : 'International'}
                </b>
              </div>
            </div>
            <div className={autoApprovalClasses}>
              <Icon
                width={12.48}
                name={autoApproval ? 'checkIcon' : 'xIconCircle'}
              />
              <p>
                Auto approval: <b>{autoApproval ? 'ON' : 'OFF'}</b>
              </p>
            </div>
          </div>
          <div className="order-details-column-group__column order-details-column-group__column--2">
            <div className="order-card-details__data">
              <div className="details-data__row">
                <p>
                  Customer:
                  <strong>{props.item.customerName || ''}</strong>
                </p>
              </div>
              <div className="details-data__row">
                <p>
                  Order Date:
                  <strong>{formatDate(props.item.createdAt)}</strong>
                </p>
              </div>
              <div className="details-data__row">
                <div className="moderation-has-comment">
                  <Icon
                    name="comment"
                    fillColor={{
                      default: hasComments ? '#4573BB' : '#D2D2D2',
                    }}
                  />
                  <p>{!hasComments ? 'No comments added' : 'Comments added'}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="order-card-actions">
          <GFXButton
            variant="secondary"
            onClick={() => props.viewDesignAction(props.item.itemId)}
          >
            <EyeIcon />
            View Design
          </GFXButton>
        </div>
      </div>
    </div>
  );
};
