import React from 'react';
import {useAppSelector} from '../../app/hooks';
import {ReactComponent as ImageIcon} from '../../assets/icons/upload-image-icon.svg';
import {ReactComponent as ImagePlusIcon} from '../../assets/icons/image-plus-icon.svg';
import {selectShop} from '../../features/shops/selectedShop';
import {GFXToastLaunch} from '../ToastMessage/ToastMessage';
import FileInput from '../FileInput';
import {IImage} from '@gfxco/contracts';
import {
  validateFileLimits,
  IImageFile,
  updateFileName,
} from '../../libs/uploadImage';

import './FileDropzone.scss';

interface RequiredProps {}

interface OptionalProps {
  dropzoneText?: React.ReactNode | string;
  callToAction?: React.ReactNode;
  allowSelectImages?: boolean;
  limits?: {
    width: number;
    height: number;
    weight: number;
    numberOfFiles: number;
  };
  onFileAdded?: (file: File[]) => void;
}

type FileDropzoneProps = RequiredProps & OptionalProps;

const DEFAULT_LABELS_LIMITS = {
  width: 6000,
  height: 6000,
  weight: 50,
};

const FileDropzone: React.FC<FileDropzoneProps> = ({
  dropzoneText,
  allowSelectImages = false,
  callToAction,
  limits,
  onFileAdded,
}) => {
  const shopSelected = useAppSelector(selectShop);
  const shopId = shopSelected?.id;
  const [imagesSelected, setImagesSelected] = React.useState<IImage[]>([]);
  const [dragging, setDragging] = React.useState(false);
  const inputFileRef = React.useRef<HTMLInputElement>(null);
  const DROPZONE_LIMITS = {...DEFAULT_LABELS_LIMITS, ...limits};

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
    const files = event.dataTransfer?.files;
    await onNewFiles(files!);
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
  };

  const handleDropzoneClick = (event: React.MouseEvent<HTMLInputElement>) => {
    event.preventDefault();
    inputFileRef.current?.click();
  };

  const onNewFiles = async (fileList: FileList) => {
    const imagesLoaded: IImageFile[] = [];
    const files = Array.from(fileList);
    if (
      DROPZONE_LIMITS.numberOfFiles &&
      files.length > DROPZONE_LIMITS.numberOfFiles
    ) {
      GFXToastLaunch(
        `You can only upload ${DROPZONE_LIMITS.numberOfFiles} file(s)`,
        2000,
        {
          showAt: 'top',
          right: '3.5rem',
          top: '3.5rem',
          showIcon: true,
          alertType: 'danger',
        },
      );
      return;
    }
    for (const file of files) {
      try {
        const image = await handleFileChange(file);
        if (!image) {
          continue;
        }
        imagesLoaded.push({file, name: image.name});
      } catch (error: any) {
        imagesLoaded.push({
          isError: true,
          name: file.name,
          error: error.message!,
        });
        GFXToastLaunch(error.message, 100000, {
          showAt: 'top',
          right: '3.5rem',
          top: '3.5rem',
          showIcon: true,
          alertType: 'danger',
        });

        throw error;
      }
    }

    handleImageUploaded(imagesLoaded);
  };

  const handleFileChange = async (file: File) => {
    if (!shopId) {
      return;
    }
    try {
      const result = await validateFileLimits({
        file,
        limits: DROPZONE_LIMITS,
        fileName: file.name,
        shopId,
      });

      return result;
    } catch (error: any) {
      GFXToastLaunch(error.message, 100000, {
        showAt: 'top',
        right: '3.5rem',
        top: '3.5rem',
        showIcon: true,
        alertType: 'danger',
      });
      throw error;
    }
  };

  const handleImageUploaded = (imagesFile: IImageFile[]) => {
    const imagesErrored = imagesFile.filter((item) => !!item.isError);

    const imagesLoaded: File[] = [];
    const images = imagesFile
      .filter((item) => !item.isError)
      .map((item) => {
        let imageUrl = '';
        let newFileName = item.name;
        if (item.file) {
          newFileName = updateFileName(item.file.name, item.file.type);
          imageUrl = URL.createObjectURL(item.file);
          imagesLoaded.push(item.file);
        }
        return {name: newFileName, imageUrl};
      });

    setImagesSelected((prev) => [...prev, ...imagesErrored, ...images]);
    if (onFileAdded) {
      onFileAdded(imagesLoaded);
    }
  };

  const handleError = (errorMessage: string) => {
    GFXToastLaunch(errorMessage, 100000, {
      showAt: 'top',
      right: '3.5rem',
      top: '3.5rem',
      showIcon: true,
      alertType: 'danger',
    });
  };

  return (
    <div id="FileDropzone">
      <div className="body">
        <div className="upload-container">
          <div
            className={`icon-container ${
              allowSelectImages ? 'icon-container-select' : ''
            }`}
          >
            <div data-tooltip="Add images">
              <FileInput
                ref={inputFileRef}
                text={''}
                onFileChange={handleFileChange}
                onFileLoaded={handleImageUploaded}
                icon={callToAction ? undefined : <ImageIcon />}
                button={callToAction}
                omitUpload
                numberOfFiles={DROPZONE_LIMITS.numberOfFiles}
                onError={handleError}
              />
            </div>
          </div>
          <div
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onDragLeave={handleDragLeave}
            onDragEnter={handleDragEnter}
            onClick={handleDropzoneClick}
            className={`images-container
              ${imagesSelected.length === 0 ? 'images-empty-container' : ''}
              ${dragging ? 'dragging' : ''}
            `}
          >
            <div id="DropzoneDrag" className={`${dragging ? 'dragging' : ''}`}>
              <div>
                <div className="images-text">
                  <ImagePlusIcon />
                  <div className="images-action-text">
                    {dropzoneText && typeof dropzoneText === 'string' && (
                      <span>{dropzoneText}</span>
                    )}
                    {dropzoneText && typeof dropzoneText !== 'string' ? (
                      <>{dropzoneText}</>
                    ) : (
                      <>
                        <p>
                          Drag & drop{' '}
                          <span className="bold-text">your images here</span>
                        </p>
                      </>
                    )}
                  </div>
                </div>
                <div className="images-action-text note-text">
                  <p>
                    Remember, you can upload any image from{' '}
                    <span className="bold-text">100px by 100px</span> to{' '}
                    <span className="bold-text">
                      {DROPZONE_LIMITS.width}px by {DROPZONE_LIMITS.height}px
                    </span>
                    . We suggest using high resolution images of{' '}
                    <span className="bold-text">300 DPI </span>
                    or higher.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileDropzone;
