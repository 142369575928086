import React from 'react';
import Icon from '../Icons/Icon';
import './HasNeckLabel.scss';

interface HasNeckLabelProps {
  hasLabel: boolean;
  hasCustomText?: string;
  hasNotCustomText?: string;
}

const HasNeckLabel: React.FC<HasNeckLabelProps> = (props) => {
  const {hasLabel, hasCustomText, hasNotCustomText} = props;
  const hasText = hasCustomText || 'Neck Label Available';
  const hasNotText = hasNotCustomText || 'Neck Label not Available';

  return (
    <div className="HasLabel">
      {hasLabel ? (
        <>
          <Icon name="hasLabel"></Icon>
          <span className="HasLabel__Text">{hasText}</span>
        </>
      ) : (
        <>
          <Icon name="notHasLabel"></Icon>
          <span className="HasLabel__Text">{hasNotText}</span>
        </>
      )}
    </div>
  );
};

export default HasNeckLabel;
