import React from 'react';
import Skeleton from 'react-loading-skeleton';
import Select, {
  components,
  ControlProps,
  OptionProps,
  Options,
} from 'react-select';
import ProductModalHeader from '../ProductModalHeader';
import {useAppSelector} from '../../app/hooks';
import {selectShop} from '../../features/shops/selectedShop';
import {getProductSkus} from '../../api';
import {
  ProductSkusRequest,
  ProductSkusResponse,
  INextStep,
} from '@gfxco/contracts';

import './SelectProductSku.scss';
import ProductSku from './ProductSku';
import Icon from '../Icons/Icon';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

interface OptionalProps {
  productType?: string;
  initialSkuDescription?: string;
  initialPrinter?: string;
}

interface RequiredProps {
  onNextStep: (params: INextStep) => void;
}

interface OptionType {
  value: string;
  label: string;
  icon?: React.ReactNode;
}

const TITLE = 'Select your product catalog';
type SelectProductSkuProps = RequiredProps & OptionalProps;

export const SelectProductSku: React.FC<SelectProductSkuProps> = ({
  productType = 'shirt',
  onNextStep,
  initialSkuDescription,
  initialPrinter,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [productSkus, setProductSkus] = React.useState<ProductSkusResponse[]>(
    [],
  );
  const selectedShop = useAppSelector(selectShop);
  const shopId = selectedShop!.id;
  const [isError, setIsError] = React.useState(false);
  const [printerFilter, setPrinterFilter] = React.useState('ALL');

  const handleOnClick = (productSku: ProductSkusResponse) => {
    const nextStep = {
      step: 4,
      data: {
        colors: productSku.colors,
        sizes: productSku.sizes,
        printer: productSku.printer,
        skuDescription: productSku.description,
        hasLabel: productSku.hasLabel,
        manufacturerId: productSku.manufacturerId,
      },
    };
    onNextStep(nextStep);
  };

  React.useEffect(() => {
    if (!isLoading) {
      getProductSkusData();
    }
  }, []);

  React.useEffect(() => {
    getProductSkusData();
  }, [printerFilter]);

  const getProductSkusData = async () => {
    try {
      if (!shopId) return;
      setIsLoading(true);
      const params: ProductSkusRequest = {
        productType,
        shopId,
        printerCode: printerFilter === 'ALL' ? undefined : printerFilter,
      };
      const productSkus = await getProductSkus(params);
      if (productSkus) {
        setProductSkus(productSkus);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsError(true);
    }
  };

  if (isError) {
    return <div>Error Loading the Skus!.</div>;
  }

  const options: Options<OptionType> = [
    {value: 'ALL', label: 'All catalogs available'},
    {
      value: 'PRINTFUL',
      label: 'Catalog',
      icon: <Icon name="printfulLogo" height={12} width={82} />,
    },
    {
      value: 'ZIFT',
      label: 'Catalog',
      icon: <Icon name="fifthSunLogo2" />,
    },
  ];

  const CustomControl = ({
    children,
    ...props
  }: ControlProps<OptionType, false>) => {
    const currentValue = props.getValue();

    return (
      <components.Control {...props}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '0.5rem',
            padding: '0 0.5rem',
          }}
        >
          {currentValue[0] && currentValue[0].icon}
          {children}
        </div>
      </components.Control>
    );
  };

  const CustomOption = ({
    children,
    ...props
  }: OptionProps<OptionType, false>) => {
    return (
      <components.Option {...props}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '0.5rem',
            padding: '0 0.5rem',
          }}
        >
          {props.data.icon}
          {children}
        </div>
      </components.Option>
    );
  };

  return (
    <div className="SelectProductSku">
      <ProductModalHeader
        title={TITLE}
        subtitle="Choose the product you want to customize:"
      />
      <div>
        <div className="SelectProductSku__filter">
          <label htmlFor="printerFilter">
            {' '}
            <OverlayTrigger
              placement="top"
              trigger={['hover', 'focus']}
              overlay={
                <Tooltip className="white-tooltip">
                  Your catalogs will be a reflection of the fulfillment services
                  available, you are able to create designs even if your
                  fulfillment is not synced, but you have to configure it to
                  accept any future orders.
                </Tooltip>
              }
            >
              <div>
                <Icon name="info" />
              </div>
            </OverlayTrigger>{' '}
            Catalog Filter
          </label>
          <Select
            isSearchable={false}
            id="printerFilter"
            value={options.find((option) => option.value === printerFilter)}
            onChange={(e) => setPrinterFilter(e?.value ?? 'ALL')}
            options={options}
            components={{
              Control: CustomControl,
              Option: CustomOption,
            }}
            isDisabled={isLoading}
          />
        </div>
      </div>
      {!isLoading && (
        <div className="SelectProductSku__container">
          {productSkus.map((item) => {
            const itemKey = `${item.description}-${item.printer}`;
            const initialKey = `${initialSkuDescription}-${initialPrinter}`;
            const isSelected = initialKey === itemKey;
            return (
              <ProductSku
                key={itemKey}
                item={item}
                productType={productType}
                isSelected={isSelected}
                handleOnClick={handleOnClick}
              />
            );
          })}
        </div>
      )}
      {isLoading && (
        <Skeleton
          className="SelectProductSku__item skeleton"
          containerClassName="SelectProductSku__container"
          count={5}
          inline
        />
      )}
    </div>
  );
};
export default SelectProductSku;
