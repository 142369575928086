import React from 'react';
import {GFXEditor} from '../GFXEditor';
import './TemplateBuilder.scss';
import {useGFXInstance} from '@best-apps/gfx-editor';
import {Color, IImage} from '@gfxco/contracts';
import LeftToolbarComponent from './Toolbars/LeftToolbar';
import TopToolbarFonts from './Toolbars/TopToolbarFonts';
import GarmentColorBar from './Toolbars/GarmentColorBar';
import TopToolbarImage from './Toolbars/TopToolbarImage';

type TemplateBuilderEditorOptionalProps = {
  canvasId?: number;
  templateId?: number;
};

type TemplateBuilderEditorRequiredProps = {
  shopId: number;
  productType: string;
  manufacturerId: number;
  printer: string;
  height?: number;
  displayColors?: Color[];
  selectedColors?: Color[];
  width?: number;
  setImageMap: (imageMap: IImage, slot: string) => void;
  setImageMapForDuplicated: (
    parentSlotUuid: string,
    parentImageId: number | null | undefined,
    newSlotUuid: string,
  ) => void;
  step: number;
  activateSaveWarning: () => void;
};
type TemplateBuilderEditorProps = TemplateBuilderEditorOptionalProps &
  TemplateBuilderEditorRequiredProps;

const TemplateBuilderEditor: React.FC<TemplateBuilderEditorProps> = (props) => {
  const gfx = useGFXInstance();
  const [isGfxLoaded, setIsGfxLoaded] = React.useState(false);
  const [currentColor, setCurrentColor] = React.useState<string>('white');
  const [initialSelectedColors, setInitialSelectedColors] = React.useState<
    Color[]
  >([]);

  React.useEffect(() => {
    return () => {
      console.log('unmounting template builder');
    };
  }, []);

  React.useEffect(() => {
    if (gfx && !isGfxLoaded) {
      setIsGfxLoaded(true);
    }
  }, [gfx, isGfxLoaded]);

  React.useEffect(() => {
    if (gfx && gfx.state.design.color) {
      const color = gfx.state.design.color;

      const hexColor = props.displayColors?.find(
        (item) => item.name === color,
      )?.hex;
      setCurrentColor((prev) => {
        return hexColor as string;
      });
    }
  }, [gfx?.state.design.color]);

  React.useEffect(() => {
    const setColor = async () => {
      if (
        gfx &&
        props.selectedColors &&
        props.selectedColors.toString() !== initialSelectedColors.toString()
      ) {
        const isThereCurrentColor = props.selectedColors.find(
          (item) => item.name === gfx.state.design.color,
        );

        const getColorToShow = () => {
          if (isThereCurrentColor) {
            return isThereCurrentColor.name;
          }

          if (props.selectedColors && props.selectedColors.length > 0) {
            return props.selectedColors[0].name;
          }

          return 'white';
        };

        setInitialSelectedColors(props.selectedColors);

        await gfx?.actions.setPrintableColor(getColorToShow());
      }
    };
    setColor();
  }, [gfx, props.selectedColors]);

  const encodedDisplayColors = encodeURIComponent(
    JSON.stringify(props.displayColors),
  );

  const onChangeColor = async (color: Color) => {
    const hexColor = color.hex;
    const colorInfo = props.displayColors?.find(
      (item) => item.hex === hexColor,
    );
    await gfx?.actions.setPrintableColor(colorInfo?.name || 'white');
  };

  return (
    <div className="template-builder">
      {isGfxLoaded && (
        <>
          {props.step === 5 && (
            <>
              <LeftToolbarComponent
                setImageMapForDuplicated={props.setImageMapForDuplicated}
                activateSaveWarning={props.activateSaveWarning}
              />
              <GarmentColorBar
                currentColor={currentColor}
                colors={props.selectedColors || []}
                onColorClick={onChangeColor}
              />
              <TopToolbarImage
                setImageMap={props.setImageMap}
                activateSaveWarning={props.activateSaveWarning}
              />
              <TopToolbarFonts
                activateSaveWarning={props.activateSaveWarning}
              />
            </>
          )}
        </>
      )}
      <GFXEditor
        height={null}
        padding={{
          top: 50,
        }}
        width={props.width || 900}
        isTemplateBuilder={true}
        builderProductType={props.productType}
        builderManufacturerId={props.manufacturerId}
        builderPrinterCode={props.printer}
        builderShopId={props.shopId.toString()}
        interfaceType="builder"
        canvasId={props.canvasId}
        displayColors={encodedDisplayColors}
        templateId={props.templateId?.toString()}
      />
    </div>
  );
};

export default TemplateBuilderEditor;
