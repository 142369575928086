import React from 'react';
import {IInstallationConfigs} from '@gfxco/contracts';
import {useNavigate} from 'react-router-dom';
import {Pencil, ThreeDots} from 'react-bootstrap-icons';
import GFXButton from '../../../components/Button';

import Icon from '../../../components/Icons/Icon';
import StepItem from '../StepItem';

interface RequiredProps {
  isLoading: boolean;
  isOwner: boolean;
  onInstallExtension: () => Promise<void>;
}

interface OptionalProps {
  configs?: IInstallationConfigs;
}

type HomeStepsProps = RequiredProps & OptionalProps;

const HomeSteps: React.FC<HomeStepsProps> = ({
  configs,
  isLoading,
  isOwner,
  onInstallExtension,
}) => {
  const navigate = useNavigate();
  const [installExtensionState, setInstallExtensionState] =
    React.useState<string>('idle');

  const onInstallExtensionClick = async () => {
    setInstallExtensionState('loading');
    try {
      await onInstallExtension();
    } catch (error) {
      console.error(error);
    }
    setInstallExtensionState('idle');
  };

  const ownerSteps = () => {
    return (
      <>
        <StepItem
          index={1}
          title="Sync your store"
          status={configs?.ecommerceSync ? 'completed' : 'not-completed'}
          loading={isLoading}
          description={
            <div className="home-steps-text">
              When you log in from your Shopify store, we automatically sync
              your APKs with our system. You can check this information on
              <Icon name="myStoryIcon" fillColor={{default: '#4573BB'}} />
              <b className="click-info" onClick={() => navigate('my-store')}>
                My store
              </b>{' '}
              and on your Shopify apps section <b>{`GFX Shopify plugin `}</b>
              any time you need.
            </div>
          }
        />
        <StepItem
          index={2}
          title="Upload your images"
          status={configs?.imagesCreated ? 'completed' : 'not-completed'}
          loading={isLoading}
          description={
            <div className="box-content">
              <div className="home-steps-text">
                Go to <Icon name="productIcon" /> <b>Products</b> and click on
                <Icon name="image" /> <b>Images</b>. There, you can create
                collections and upload your design images.
              </div>
            </div>
          }
        />
        <StepItem
          index={3}
          title="Customize your design"
          status={configs?.totalTemplates ? 'completed' : 'not-completed'}
          loading={isLoading}
          description={
            <div className="box-content">
              <div className="home-steps-text">
                Our design builder lets us create interactive printable designs.
                Go to <Icon name="productIcon" />
                <b>Products</b> {'>'} click <Pencil />
                <b>Designs</b>, and create a <b>Design from scratch</b>, or use
                our GFX default template.
              </div>
              <button
                onClick={() => navigate('designs/create')}
                className="button-box"
              >
                Create a design from scratch
              </button>
            </div>
          }
        />
        <StepItem
          index={4}
          title="Publish on your Shopify"
          status={configs?.totalPublished ? 'completed' : 'not-completed'}
          loading={isLoading}
          description={
            <div className="box-content">
              <div className="home-steps-text">
                Once you have your design ready, click{' '}
                <div className="icon-menu">
                  <ThreeDots />
                </div>{' '}
                and look for the <b>Publish</b> option. This will take you to
                your Shopify store and will let you set up your inventory,
                sizes, and everything you need to sell (this needs to be done
                for every design).
              </div>
              <button
                onClick={() => navigate('designs')}
                className="button-box"
              >
                Go to designs
              </button>
            </div>
          }
        />
        <StepItem
          index={5}
          title="Preview on your Shopify theme"
          status={configs?.extensionInstalled ? 'completed' : 'not-completed'}
          loading={isLoading}
          description={
            <div className="box-content">
              <div
                className="home-steps-text"
                style={{
                  textAlign: 'left',
                }}
              >
                To correctly visualize your Design on your Store, customize your
                theme in Shopify.
                <button
                  style={{
                    float: 'right',
                  }}
                  onClick={onInstallExtensionClick}
                  className="button-box"
                >
                  {installExtensionState === 'loading' ? 'Installing...' : ''}
                  {configs?.extensionInstalled ? 'Check my Theme' : ''}
                  {!configs?.extensionInstalled &&
                  installExtensionState === 'idle'
                    ? 'Install extension'
                    : ''}
                </button>
              </div>
            </div>
          }
        />
        <StepItem
          index={6}
          title="Set up your fulfillment printer"
          status={configs?.printerSync ? 'completed' : 'not-completed'}
          loading={isLoading}
          description={
            <div className="box-content">
              <div className="home-steps-text">
                Go to{' '}
                <Icon name="samplePortal" fillColor={{default: '#4573BB'}} />
                <b className="no-underline">Printer Configuration.</b> There,
                you can add and configure the printer APKs of your choice to
                start designing.
                <div className="home-steps-actions">
                  <a
                    href="https://gfx-help.notion.site/Printful-setup-dc90a3e8e0cf46b8ab53434a4bca0d1a"
                    target="_blank"
                    rel="noreferrer"
                  >
                    See the complete guide
                  </a>
                  <GFXButton
                    variant="primary"
                    onClick={() => navigate('printers')}
                  >
                    Select and set your printer
                  </GFXButton>
                </div>
              </div>
            </div>
          }
        />
      </>
    );
  };

  const userSteps = () => {
    return (
      <>
        <StepItem
          index={1}
          title="Learn more about GFX"
          status="no-status"
          loading={isLoading}
          description={
            <div className="home-steps-text">
              <p>
                In GFX we empower every fan to wear, display, hold, or share
                official and personal branded products. We provide:
              </p>
              <ul>
                <li>
                  <strong>Brand Protection</strong>: Clients design products
                  aligned with their brand guidelines, including setting rules
                  for customization
                </li>
                <li>
                  <strong>One-of-One Items</strong>: Fans personalize and order
                  products through online or in-real-life retail stores.
                </li>
                <li>
                  <strong>Analytics & Insights</strong>: Real-time insights and
                  customer reports help our clients identify best sellers and
                  market trends.
                </li>
              </ul>
              <a
                href="https://gfx-help.notion.site/Getting-started-4b0c7f1f4f1a4f7e8a3b6d4e5e9f4e8b"
                target="_blank"
                rel="noreferrer"
              >
                Learn more about GFX
              </a>
            </div>
          }
        />
        <StepItem
          index={2}
          title="User permissions"
          status="no-status"
          loading={isLoading}
          description={
            <div className="box-content">
              <div className="home-steps-text">
                Account owners will designate user access to the platform to
                monitor their work teams. You can always ask for new permissions
                to your account owner.
              </div>
            </div>
          }
        />
      </>
    );
  };

  return (
    <div id="HomeSteps" className="home-steps">
      {isOwner ? ownerSteps() : userSteps()}
    </div>
  );
};

export default HomeSteps;
